import React from 'react';
import { connect } from 'react-redux';
import { FilterTypes } from 'tennisconcrete-node';

import { ISelectOption } from '../../entities/ISelectOption';
import TcSelect from '../../components/TcSelect';
import TcCheckbox from '../../components/TcCheckbox';
import TcButton from '../../components/TcButton';
import { getAvgResults, getP1Results, getP2Results } from '../../redux/actions/matchActions';

interface IClassProps {
    location?: any;
    history?: any;
    filterTypeOptions: ISelectOption[];

    p1Loading: boolean;
    p2Loading: boolean;
    avgLoading: boolean;

    getP1Results: (playerId: string, filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) => any;
    getP2Results: (playerId: string, filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) => any;
    getAvgResults: (filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) => any;
}

// Tbh this can be moved into a seperate picker component...
interface IClassState {
    // Surface filters
    surfaceHard: boolean,
    surfaceIndoor: boolean,
    surfaceClay: boolean,
    surfaceGrass: boolean

    // Tour filters
    tourWTA: boolean,
    tourITF: boolean,
    tourATP: boolean,
    tourChallenger: boolean

    filterType: FilterTypes;
    filterValue: string;

    filterDisabled: boolean;

    p1Id?: string;
    p2Id?: string;

    // For handling any state change
    [input: number]: any;
}


class MatchFiltersComp extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        const selectedValue = JSON.parse(props.filterTypeOptions[0].value);
        this.state = {
            surfaceHard: true,
            surfaceIndoor: false,
            surfaceClay: false,
            surfaceGrass: false,

            tourWTA: true,
            tourITF: false,
            tourATP: false,
            tourChallenger: false,

            filterType: selectedValue.filterType,
            filterValue: selectedValue.filterValue,

            filterDisabled: false,

            p1Id: undefined,
            p2Id: undefined
        }
    }

    public componentDidMount() {
        this.initializeView();
    }

    private initializeView() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const p1Id = queryParams.get('p1');
        const p2Id = queryParams.get('p2');
        if (p1Id === null || p2Id === null || p1Id === 'null' || p2Id === 'null') this.props.history.push('/')
        else {
            this.setState({
                p1Id: p1Id,
                p2Id: p2Id
            }, () => this.getData());
        }
    }

    handleFilterChage(e: any) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const filterType = JSON.parse(value).filterType;
        const filterValue = JSON.parse(value).filterValue;
        console.log(value)
        this.setState({
            filterType: filterType,
            filterValue: filterValue
        });
    }

    onFilterClick() {
        this.setState({
            filterDisabled: true
        }, () => this.getData());
        setTimeout(() => {
            this.setState({
                filterDisabled: false
            })
        }, 2000);
    }

    private getData() {
        if (this.state.p1Id && this.state.p2Id) {
            const surfaces = [];
            if (this.state.surfaceHard) surfaces.push('hard');
            if (this.state.surfaceIndoor) surfaces.push('indoor');
            if (this.state.surfaceClay) surfaces.push('clay');
            if (this.state.surfaceGrass) surfaces.push('grass');

            const tours = [];
            if (this.state.tourWTA) tours.push('wta');
            if (this.state.tourITF) tours.push('itf');
            if (this.state.tourATP) tours.push('atp');
            if (this.state.tourChallenger) tours.push('challenger');

            this.props.getP1Results(this.state.p1Id, this.state.filterType, this.state.filterValue, surfaces, tours);
            this.props.getP2Results(this.state.p2Id, this.state.filterType, this.state.filterValue, surfaces, tours);
            this.props.getAvgResults(this.state.filterType, this.state.filterValue, surfaces, tours);
        }
    }

    onChangeCheck(e: any) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    private filterButtonDisabled() {
        const surfaceDisabled = !this.state.surfaceHard && !this.state.surfaceIndoor && !this.state.surfaceClay && !this.state.surfaceGrass;
        const tourDisabled = !this.state.tourWTA && !this.state.tourITF && !this.state.tourATP && !this.state.tourChallenger;
        const loadingDisabled = this.props.p1Loading || this.props.p2Loading || this.props.avgLoading || this.state.filterDisabled;
        return surfaceDisabled || tourDisabled || loadingDisabled;
    }

    render() {
        return (
            <section className="matchFilter tcSection">
                <h2>Filter options</h2>
                <div className="filterOptions">
                    <div className="filterGroups">
                        <div className="filterGroup">
                            <TcCheckbox tcId="tourWTA" tcName="tourWTA" tcValue={this.state.tourWTA} tcLabel="WTA" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="tourITF" tcName="tourITF" tcValue={this.state.tourITF} tcLabel="ITF" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="tourATP" tcName="tourATP" tcValue={this.state.tourATP} tcLabel="ATP" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="tourChallenger" tcName="tourChallenger" tcValue={this.state.tourChallenger} tcLabel="Challenger" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                        </div>
                        <div className="filterGroup">
                            <TcCheckbox tcId="surfaceHard" tcName="surfaceHard" tcValue={this.state.surfaceHard} tcLabel="Hard Court" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="surfaceIndoor" tcName="surfaceIndoor" tcValue={this.state.surfaceIndoor} tcLabel="Indoors" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="surfaceClay" tcName="surfaceClay" tcValue={this.state.surfaceClay} tcLabel="Clay Court" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="surfaceGrass" tcName="surfaceGrass" tcValue={this.state.surfaceGrass} tcLabel="Grass Court" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                        </div>
                    </div>
                    <div className="filterGroups">
                        <TcSelect tcId="inputTour" tcName="inputTour" tcOnChange={(e: any) => this.handleFilterChage(e)} tcOptions={this.props.filterTypeOptions} />
                        <TcButton tcDisabled={this.filterButtonDisabled()} tcOnClick={() => this.onFilterClick()}>Filter</TcButton>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        filterTypeOptions: state.match.filterTypeOptions,

        p1Loading: state.match.p1Loading,
        p2Loading: state.match.p2Loading,
        avgLoading: state.match.avgLoading
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getP1Results: (playerId: string, filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) => dispatch(getP1Results(playerId, filterType, filterValue, surfaces, tours)),
        getP2Results: (playerId: string, filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) => dispatch(getP2Results(playerId, filterType, filterValue, surfaces, tours)),
        getAvgResults: (filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) => dispatch(getAvgResults(filterType, filterValue, surfaces, tours))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchFiltersComp);
