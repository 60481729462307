import React from 'react';
import { getClassNames } from '../scripts/mapFunctions';

interface IProps {
    tcOnChange?: any;
    tcLabel?: string;
    tcClassLabel?: string;
    tcId?: string;
    tcName?: string;
    tcClass?: string;
    tcType?: string;
    tcPlaceholder?: string;
    tcValue?: string;
}

export default function TcInput(props: IProps) {
    const tcOnChange = (e: any) => {
        if (props.tcOnChange) props.tcOnChange(e);
    };
    return (
        <div className="tcInputContainer">
            {props.tcLabel &&
                props.tcId && (
                    <label className={`tcLabel ${getClassNames(props.tcClassLabel)}`} htmlFor={props.tcId}>
                        {props.tcLabel}
                    </label>
                )}
            <input
                onChange={(e) => tcOnChange(e)}
                id={props.tcId}
                name={props.tcName}
                type={props.tcType}
                value={props.tcValue}
                placeholder={props.tcPlaceholder}
                className={`tcInput ${getClassNames(props.tcClass)}`}
            />
        </div>
    );
}
