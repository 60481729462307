import React from 'react';
import { connect } from 'react-redux';
import { IScheduledMatch, IScheduledTournament } from 'tennisconcrete-node';
import TcMain from '../../components/TcMain';
import TcMainHeader from '../../components/TcMainHeader';
import { Link, Redirect } from 'react-router-dom';

import './ScheduleTournamentView.scss';
import TcLoadingScreen from '../../components/TcLoadingScreen';
import TcInput from '../../components/TcInput';

interface IScheduleTournamentViewProps {
    schedule: IScheduledTournament[];
    error: string;
    loading: boolean;
    match: any;
}

interface IScheduleTournamentViewState {
    tournament?: IScheduledTournament | null;
    scheduleFilterInput: "";

    // For handling any state change
    [input: number]: any;
}

class ScheduleTournamentView extends React.Component<IScheduleTournamentViewProps, IScheduleTournamentViewState> {

    constructor(props: any) {
        super(props);
        this.state = {
            tournament: undefined,
            scheduleFilterInput: ""
        }
    }

    componentDidMount() {
        this.setSelectedTournament();
    }

    private onInputChange(e: any) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    setSelectedTournament() {
        const tournament = this.props.schedule.filter(tourn => tourn.tournamentId.toString() === this.props.match.params.id);
        if (tournament.length === 1) {
            this.setState({
                tournament: tournament[0]
            })
        } else {
            this.setState({
                tournament: null
            })
        }
    }

    displayViewContent() {
        if (this.state.tournament === null) {
            return <Redirect to="/schedule" />
        }
        else if (this.state.tournament !== undefined) {
            return (
                <React.Fragment>
                    <TcMainHeader tcHeader={`${this.state.tournament.tournamentName} (${this.state.tournament.scheduledMatches.length})`} />
                    <section className="scheduleTournament">
                        <TcInput tcOnChange={(e: any) => this.onInputChange(e)} tcName="scheduleFilterInput" tcType="text" tcPlaceholder="Search for matches" />
                        <ul>
                            {this.state.tournament.scheduledMatches.filter((match: IScheduledMatch) => match.playerA.fullname.toLowerCase().includes(this.state.scheduleFilterInput.toLowerCase()) || match.playerB.fullname.toLowerCase().includes(this.state.scheduleFilterInput.toLowerCase())).map((match: IScheduledMatch) => {
                                return (
                                    <li key={match.matchId}>
                                        <Link to={`/match?p1=${match.playerA.playerId}&p2=${match.playerB.playerId}`}>{`${match.playerA.fullname} - ${match.playerB.fullname}`}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                        <Link to="/schedule" className="tcButton">Back to schedule</Link>
                    </section>
                </React.Fragment>
            )
        } else {
            return <TcLoadingScreen />
        }
    }

    render() {
        return (
            <TcMain tcClass={["scheduleTournamentView"]}>
                {this.displayViewContent()}
            </TcMain>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        schedule: state.schedule.schedule
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTournamentView);
