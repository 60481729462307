import React from 'react';
import { getClassNames } from '../scripts/mapFunctions';
import TextareaAutosize from 'react-textarea-autosize';

interface IProps {
    tcMinRows?: number;
    tcOnChange?: any;
    tcDisabled?: boolean;
    tcValue?: string;
    tcPlaceholder?: string;
    tcId?: string;
    tcName?: string;
    tcClass?: string;
}

export default function TcTextarea(props: IProps) {

    const tcOnChange = (e: any) => {
        if (props.tcOnChange) props.tcOnChange(e);
    };

    return (
        <TextareaAutosize
            minRows={props.tcMinRows}
            disabled={props.tcDisabled}
            value={`${props.tcValue}`}
            onChange={(e) => tcOnChange(e)}
            id={props.tcId}
            name={props.tcName}
            placeholder={props.tcPlaceholder}
            className={`tcTextarea${getClassNames(props.tcClass)}`}
        />
    );
}
