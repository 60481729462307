import { DateTime } from 'luxon';
import React from 'react';
import { connect } from 'react-redux';
import { IPlayer, parseId, tryParseId } from 'tennisconcrete-node';
import TcMain from '../../components/TcMain';
import MatchDeltasComp from './MatchDeltasComp';
import MatchFiltersComp from './MatchFiltersComp';
import MatchHistoryComp from './MatchHistoryComp';
import MatchNotesComp from './MatchNotesComp';
import MatchStatsComp from './MatchStatsComp';
import MatchModelComp from './MatchModelComp';

import './../../styles/PlayerView.scss';
import './MatchView.scss';

interface IClassProps {
    location?: any;
    history?: any;

    p1Results: IPlayer;
    p2Results: IPlayer;

    p1Loading: boolean;
    p2Loading: boolean;

    p1Error?: string;
    p2Error?: string;
}

interface IClassState {
    p1Id?: number;
    p2Id?: number;
}

class MatchView extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
            p1Id: undefined,
            p2Id: undefined,
        }
    }

    public componentDidMount() {
        this.initializeView();
    }

    private initializeView() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const p1Id = queryParams.get('p1');
        const p2Id = queryParams.get('p2');
        if (p1Id === null || p2Id === null || p1Id === 'null' || p2Id === 'null' || !tryParseId(p1Id) || !tryParseId(p2Id)) this.props.history.push('/')
        else {
            this.setState({
                p1Id: parseId(p1Id),
                p2Id: parseId(p2Id)
            });
        }
    }

    displayHeader() {
        if (this.props.p1Error || this.props.p2Error) {
            return (
                <section className="tcMainHeader">
                    <h1>Error loading match...</h1>
                </section>
            )
        }
        else if (this.props.p1Results !== undefined && this.props.p2Results !== undefined) {
            return (
                <section className="tcMainHeader">
                    <h1>{`${this.props.p1Results.fullName}(${this.props.p1Results.results.length})`}</h1>
                    <p>{this.getPlayerInfo(this.props.p1Results)}</p>
                    <h1>{`${this.props.p2Results.fullName}(${this.props.p2Results.results.length})`}</h1>
                    <p>{this.getPlayerInfo(this.props.p2Results)}</p>
                </section>
            )
        }
        else {
            return (
                <section className="tcMainHeader">
                    <h1>Loading match...</h1>
                </section>
            )
        }
    }

    getPlayerInfo(player: IPlayer) {
        if (player) {
            let playerInfo = [];
            if (player.gender !== null) playerInfo.push(player.gender);
            if (player.country.name !== null) playerInfo.push(player.country.name);
            if (player.dateOfBirth !== null) {
                const dt = DateTime.fromJSDate(player.dateOfBirth, { zone: "utc" });
                playerInfo.push(dt.toRelative({ unit: 'years' })!.split(' ')[0] + ' years old');
            }
            return playerInfo.join(' · ');
        } else {
            return '-';
        }
    }

    private displayContent() {
        if (this.state.p1Id !== undefined && this.state.p2Id !== undefined) {
            return (
                <React.Fragment>
                    <MatchFiltersComp location={this.props.location} history={this.props.history} />
                    <MatchModelComp p1Id={this.state.p1Id} p2Id={this.state.p2Id} location={this.props.location} history={this.props.history} />
                    <MatchNotesComp p1Id={this.state.p1Id} p2Id={this.state.p2Id} location={this.props.location} history={this.props.history} />
                    <MatchDeltasComp />
                    <MatchStatsComp />
                    <MatchHistoryComp />
                </React.Fragment>
            )
        }
    }


    render() {
        return (
            <TcMain tcClass="matchView">
                {this.displayHeader()}
                {this.displayContent()}
            </TcMain>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        p1Results: state.match.p1Results,
        p2Results: state.match.p2Results,

        p1Loading: state.match.p1Loading,
        p2Loading: state.match.p2Loading,

        p1Error: state.match.p1Error,
        p2Error: state.match.p2Error,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchView);
