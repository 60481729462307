import React from 'react';
import { connect } from 'react-redux';
import { IPlayer, IPlayerNotes } from 'tennisconcrete-node';
import TcErrorScreen from '../../components/TcErrorScreen';
import TcLoadingScreen from '../../components/TcLoadingScreen';
import TcNoteSection from '../../components/TcNoteSection';
import { getPlayerNotesAct, putPlayerNotesAct } from '../../redux/actions/playerActions';

interface IClassProps {
    playerId: number;

    // Redux
    player?: IPlayer;
    playerError?: string;
    playerLoading: boolean;

    notes?: IPlayerNotes;
    notesError?: string;
    notesLoading: boolean;

    putNotesAct: (playerId: number, notes: string) => any;
    getPlayerNotesAct: (playerId: number) => any;

}

interface IClassState {
}

class PlayerNotesComp extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    public componentDidMount() {
        this.props.getPlayerNotesAct(this.props.playerId);
    }

    public correctPlayerInfo(): boolean {
        return this.props.player !== undefined && this.props.playerId === this.props.player.playerId
    }

    private displayViewContent() {
        if (this.props.notesError !== undefined) {
            return <TcErrorScreen tcError={this.props.notesError} />
        }
        else if (this.props.player !== undefined && this.correctPlayerInfo() && this.props.notes !== undefined) {
            return (
                <React.Fragment>
                    <TcNoteSection tcClass={`notesContainer`}
                        player={this.props.player}
                        putNotesAct={(playerId: number, notes: string) => this.props.putNotesAct(playerId, notes)}
                        notes={this.props.notes}
                        notesError={this.props.notesError}
                        notesLoading={this.props.notesLoading}
                    />
                </React.Fragment>
            )
        }
        else {
            return <TcLoadingScreen />
        }
    }

    render() {
        return (
            <section className="tcSection playerNotes">
                <h2>Player notes</h2>
                {this.displayViewContent()}
            </section>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        player: state.player.player,
        playerError: state.player.playerError,
        playerLoading: state.player.playerLoading,
        notes: state.player.notes,
        notesError: state.player.notesError,
        notesLoading: state.player.notesLoading
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getPlayerNotesAct: (playerId: number) => dispatch(getPlayerNotesAct(playerId)),
        putNotesAct: (playerId: number, notes: string) => dispatch(putPlayerNotesAct(playerId, notes)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerNotesComp);
