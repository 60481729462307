import { IPlayerInfo } from "tennisconcrete-node";
import { getHttpRequest } from "../../scripts/HttpService";

export enum PlayersActionTypes {
    SET_PLAYERS = "SET_PLAYERS_MATCH",
    SET_PLAYERS_ERROR = "SET_PLAYERS_ERROR_MATCH",
    SET_PLAYERS_LOADING = "SET_PLAYERS_LOADING_MATCH",
}

export function setPlayersAct(players: IPlayerInfo[]) {
    return {
        type: PlayersActionTypes.SET_PLAYERS,
        players: players
    }
}

export function setPlayersErrorAct(error: string) {
    return {
        type: PlayersActionTypes.SET_PLAYERS_ERROR,
        error: error
    }
}

export function setPlayersLoadingAct(loading: boolean) {
    return {
        type: PlayersActionTypes.SET_PLAYERS_LOADING,
        loading: loading
    }
}

export function getPlayersAct(query: string) {
    return getHttpRequest(`/api/player/search?query=${query}`, setPlayersAct, setPlayersErrorAct, setPlayersLoadingAct);
}