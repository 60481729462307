


import React from 'react';
import { getClassNames } from '../scripts/mapFunctions';

interface IProps {
    tcOnChange?: any;
    tcValue?: any;
    tcLabel?: string;
    tcId?: string;
    tcName?: string;
    tcClass?: string;
    tcClassLabel?: string;
    tcClassContainer?: string;
}

export default function TcCheckbox(props: IProps) {
    const tcOnChange = (e: any) => {
        if (props.tcOnChange) props.tcOnChange(e);
    };
    return (
        <div className={`tcCheckboxContainer${getClassNames(props.tcClassContainer)}`}>
            <input
                className={`tcCheckbox${getClassNames(props.tcClass)}`}
                id={props.tcId}
                name={props.tcName}
                type='checkbox'
                onChange={(e) => tcOnChange(e)}
                value={props.tcValue}
                checked={props.tcValue}
            />
            {props.tcLabel &&
                props.tcId && (
                    <label className={`tcLabel${getClassNames(props.tcClassLabel)}`} htmlFor={props.tcId}>
                        {props.tcLabel}
                    </label>
                )}
        </div>
    );
}
