import { IScheduledTournament } from "tennisconcrete-node";
import { getHttpRequest } from "../../scripts/HttpService";

export enum ScheduleActionTypes {
    SET_SCHEDULE = "SET_SCHEDULE",
    LOADING_SCHEDULE = "LOADING_SCHEDULE",
    ERROR_SCHEDULE = "ERROR_SCHEDULE"
}

export function setScheduleAct(schedule: IScheduledTournament[]) {
    return {
        type: ScheduleActionTypes.SET_SCHEDULE,
        schedule: schedule
    }
}

export function loadingScheduleAct(loading: boolean) {
    return {
        type: ScheduleActionTypes.LOADING_SCHEDULE,
        loading: loading
    }
}

export function errorScheduleAct(error: string) {
    return {
        type: ScheduleActionTypes.ERROR_SCHEDULE,
        error: error
    }
}

export function fetchScheduleAct() {
    return getHttpRequest(`/api/schedule`, setScheduleAct, errorScheduleAct, loadingScheduleAct);
}