import React from 'react';
import './TcLoading.scss';

export default function TcLoading(props: any) {
	return (
		<div className='loaderContainer'>
			<div className='lds-ellipsis'>
				<div />
				<div />
				<div />
				<div />
			</div>
		</div>
	);
}
