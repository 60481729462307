import { DateTime } from 'luxon';
import { IMatch } from 'tennisconcrete-node';

export function getDateFromISO(date: Date) {
    const dt = DateTime.fromJSDate(date, { zone: "utc" });
    return dt.toLocaleString(DateTime.DATE_MED);
};

export function getShortDateFromISO(date: Date) {
    const dt = DateTime.fromJSDate(date, { zone: "utc" });
    return dt.toLocaleString({ month: 'short', day: 'numeric' });
};

export function getDateTimeFromISO(date: Date) {
    const dt = DateTime.fromJSDate(date, { zone: "utc" });
    return dt.toLocaleString(DateTime.DATETIME_MED);
}

export function getShortDateTimeFromISO(date: Date): string {
    const dt = DateTime.fromJSDate(date, { zone: "utc" });
    return dt.toLocaleString({ hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'numeric' });
}

export function getTimeFromISO(date: Date) {
    const dt = DateTime.fromJSDate(date, { zone: "utc" });
    return dt.toLocaleString(DateTime.TIME_24_SIMPLE);
}

export function getClassNames(classNames?: string) {
    return classNames ? ` ${classNames}` : '';
};

export function jsonDateParser(key: string, value: any) {
    // eslint-disable-next-line
    var reISO = /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])([T\s](([01]\d|2[0-3])\:[0-5]\d|24\:00)(\:[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3])\:?([0-5]\d)?)?)?$/;
    // eslint-disable-next-line
    var reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;
    // first, just make sure the property is a string:
    if (typeof value === 'string') {
        // then, use regex to see if it's an ISO-formatted string
        var a = reISO.exec(value);
        if (a) {
            // if so, Date() can parse it:
            return new Date(value);
        }
        // otherwise, see if it's a wacky Microsoft-format string:
        a = reMsAjax.exec(value);
        if (a) {
            // and perform some jujitsu to make use of it:
            var b = a[1].split(/[-+,.]/);
            return new Date(b[0] ? +b[0] : 0 - +b[1]);
        }
        // here, you could insert any additional tests and parse instructions you like, for other date syntaxes...
    }
    // important: you need to return any values you're not parsing, or they die...
    return value;
};

export function getMatchScore(match: IMatch) {
    let score = '';
    if (match) {
        if (match.set1A === null) return "W.O."
        if (match.set1A !== null) score = score.concat(match.set1A + '-' + match.set1B);
        if (match.set2A !== null) score = score.concat(' · ' + match.set2A + '-' + match.set2B);
        if (match.set3A !== null) score = score.concat(' · ' + match.set3A + '-' + match.set3B);
        if (match.set4A !== null) score = score.concat(' · ' + match.set4A + '-' + match.set4B);
        if (match.set5A !== null) score = score.concat(' · ' + match.set5A + '-' + match.set5B);
    }
    return score;
};

export function getMatchRound(matchRound: string) {
    switch (matchRound) {
        case null:
            return '-';
        case 'F':
            return 'F';
        case 'SF':
            return 'SF';
        case 'QF':
            return 'QF';
        case 'R16':
            return 'R16';
        case 'R32':
            return 'R32';
        case 'R64':
            return 'R64';
        case 'R128':
            return 'R128';
        default:
            return 'Q';
    }
};

export function getMatchRoundLong(roundLong: string) {
    switch (roundLong) {
        case 'Final':
            return 'F';
        case 'Semi Final':
            return 'SF';
        case 'Quarter Final':
            return 'QF';
        case 'Round of 16':
            return 'R16';
        case 'Round of 32':
            return 'R32';
        case 'Round of 56':
            return 'R56';
        case 'Round of 64':
            return 'R64';
        case 'Round of 96':
            return 'R96';
        case 'Round of 128':
            return 'R128';
        case 'Qualifying R1':
            return 'Q1';
        case 'Qualifying R2':
            return 'Q2';
        case 'Qualifying Rnd':
            return 'Q3';
        case 'Round Robin':
            return 'RR';
        default:
            return '-';
    }
};

export function sortDescDate(a: Date, b: Date) {
    return new Date(a).getTime() - new Date(b).getTime()
}

export function sortAscDate(a: Date, b: Date) {
    if (a === null) {
        return -1;
    }

    return new Date(b).getTime() - new Date(a).getTime()
}