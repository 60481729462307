import React from 'react';
import { connect } from 'react-redux';
import TcMain from '../../components/TcMain';
import TcMainHeader from '../../components/TcMainHeader';

import './AdminView.scss';
import AdminLogComp from './AdminLogComp';
import AdminActionsComp from './AdminActionsComp';
import AdminFiltersComp from './AdminFiltersComp';
import AdminTournamentsComp from './AdminTournamentsComp';

interface IAdminViewProps {
}

interface IAdminViewState {
    disabledInterval: number;
}

class AdminView extends React.Component<IAdminViewProps, IAdminViewState> {

    constructor(props: any) {
        super(props);
        this.state = {
            disabledInterval: 2000
        }
    }

    render() {
        return (
            <TcMain tcClass="adminView">
                <TcMainHeader tcHeader="Admin" />
                <AdminLogComp />
                <AdminFiltersComp />
                <AdminActionsComp />
                <AdminTournamentsComp disabledInterval={this.state.disabledInterval} />
            </TcMain>
        );
    }
}

function mapStateToProps(state: any) {
    return {
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminView);
