import { PlayersActionTypes } from "../actions/playersActions";

const initialState = {
    players: [],
    playersError: undefined,
    playersLoading: false
};

export default function playersReducer(state = initialState, action: any) {
    switch (action.type) {
        case PlayersActionTypes.SET_PLAYERS:
            return { ...state, players: action.players };
        case PlayersActionTypes.SET_PLAYERS_ERROR:
            return { ...state, playersError: action.error };
        case PlayersActionTypes.SET_PLAYERS_LOADING:
            return { ...state, playersLoading: action.loading };
        default:
            return state;
    }
}
