import { DateTime } from 'luxon';
import React from 'react';
import { connect } from 'react-redux';
import { IPlayer, parseId, tryParseId } from 'tennisconcrete-node';
import TcMain from '../../components/TcMain';
import PlayerNotesComp from './PlayerNotesComp';

import './../../styles/PlayerView.scss';
import PlayerFiltersComp from './PlayerFiltersComp';

interface IClassProps {
    match?: any;
    location?: any;
    history?: any;

    player: IPlayer;
    playerLoading: boolean;
    playerError?: string;
}

interface IClassState {
    playerId?: number;
}

class PlayerView extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
            playerId: undefined
        }
    }

    public componentDidMount() {
        this.initializeView();
    }

    private initializeView() {
        const playerId = this.props.match.params.id;
        if (playerId === null || playerId === 'null' || !tryParseId(playerId)) this.props.history.push('/schedule')
        else {
            this.setState({
                playerId: parseId(playerId),
            });
        }
    }

    private getPlayerInfo(player: IPlayer) {
        if (player) {
            let playerInfo = [];
            if (player.gender !== null) playerInfo.push(player.gender);
            if (player.country.name !== null) playerInfo.push(player.country.name);
            if (player.dateOfBirth !== null) {
                const dt = DateTime.fromJSDate(player.dateOfBirth, { zone: "utc" });
                playerInfo.push(dt.toRelative({ unit: 'years' })!.split(' ')[0] + ' years old');
            }
            return playerInfo.join(' · ');
        } else {
            return '-';
        }
    }

    private displayHeader() {
        if (this.props.playerError) {
            return (
                <section className="tcMainHeader">
                    <h1>Error loading match...</h1>
                </section>
            )
        }
        else if (this.props.player !== undefined) {
            return (
                <section className="tcMainHeader">
                    <h1>{`${this.props.player.fullName}`}</h1>
                    <p>{this.getPlayerInfo(this.props.player)}</p>
                </section>
            )
        }
        else {
            return (
                <section className="tcMainHeader">
                    <h1>Loading player...</h1>
                </section>
            )
        }
    }

    private displayContent() {
        if (this.state.playerId !== undefined) {
            return (
                <React.Fragment>
                    <PlayerFiltersComp playerId={this.state.playerId} />
                    <PlayerNotesComp playerId={this.state.playerId} />
                </React.Fragment>
            )
        }
    }

    render() {
        return (
            <TcMain tcClass="playerView">
                {this.displayHeader()}
                {this.displayContent()}
            </TcMain>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        player: state.player.player,
        playerLoading: state.player.playerLoading,
        playerError: state.player.playerError,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerView);
