import { IAuthtoken } from "tennisconcrete-node";
import { postHttpRequest } from "../../scripts/HttpService";

export enum AuthActionTypes {
    SET_AUTHTOKEN = "SET_AUTHTOKEN_AUTH",
    ERROR_AUTHTOKEN = "ERROR_AUTHTOKEN_AUTH",
    LOADING_AUTHTOKEN = "LOADING_AUTHTOKEN_AUTH",
}

export function setAuthtokenAct(authtoken: IAuthtoken) {
    return {
        type: AuthActionTypes.SET_AUTHTOKEN,
        authtoken: authtoken
    }
}
export function errorAuthAct(error: string) {
    return {
        type: AuthActionTypes.ERROR_AUTHTOKEN,
        error: error
    }
}
export function loadingAuthAct(loading: boolean) {
    return {
        type: AuthActionTypes.LOADING_AUTHTOKEN,
        loading: loading
    }
}

export function postLoginAct(username: string, password: string) {
    return postHttpRequest(`/api/auth/login`, {
        username,
        password
    }, setAuthtokenAct, errorAuthAct, loadingAuthAct);
}