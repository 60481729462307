import React from 'react';
import { connect } from 'react-redux';
import { IPlayer, StatisticService, StatisticTypes, ITourAverage } from 'tennisconcrete-node';
import TcErrorScreen from '../../components/TcErrorScreen';
import TcLoadingScreen from '../../components/TcLoadingScreen';

interface IClassProps {
    p1Results: IPlayer;
    p2Results: IPlayer;

    p1Loading: boolean;
    p2Loading: boolean;

    p1Error?: string;
    p2Error?: string;

    avgResult?: ITourAverage | null;
    avgError?: string,
    avgLoading: boolean,
}

interface IClassState {
    statisticService: StatisticService;
}

enum StatisticMethod {
    AVERAGE = "AVERAGE",
    MEDIAN = "MEDIAN",
    TOTAL = "TOTAL"
}

class MatchPointModelComp extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
            statisticService: new StatisticService()
        }
    }

    private getCalcStat(stat: StatisticTypes, method: StatisticMethod, playerNumber: number, nDecimals: number = 1): any {
        if (this.props.avgResult !== undefined) {

            const playerResults = playerNumber === 1 ? this.props.p1Results : this.props.p2Results;
            const tourAvg: number | null = this.props.avgResult === null ? null : this.state.statisticService.getAverageStatistic(this.props.avgResult, stat);
            let playerStatistic: number | null;

            if (method === StatisticMethod.AVERAGE) {
                playerStatistic = this.state.statisticService.averageStatPlayer(playerResults.playerId, playerResults.results, stat, null, nDecimals);
            } else if (method === StatisticMethod.MEDIAN) {
                playerStatistic = this.state.statisticService.medianStat(playerResults.playerId, playerResults.results, stat, null);
            } else {
                playerStatistic = this.state.statisticService.additionStat(playerResults.playerId, playerResults.results, stat, null);
            }
            return [playerStatistic, tourAvg === null || playerStatistic === null ? null : this.state.statisticService.roundNumber(playerStatistic - tourAvg, nDecimals)];
        } else {
            return [null, null]
        }
    }

    private calcBpFacedDelta(playerNumber: number, statChar: string, nDecimals: number = 1) {
        if (this.props.avgResult) {
            // eslint-disable-next-line
            const [pStat, pDelta] = this.getCalcStat(StatisticTypes.Bp_Faced_Game_Ratio, StatisticMethod.AVERAGE, playerNumber <= 1 ? 1 : 2, nDecimals);
            // eslint-disable-next-line
            const [oppStat, oppDelta] = this.getCalcStat(StatisticTypes.Bp_Chances_Game_Ratio, StatisticMethod.AVERAGE, playerNumber <= 1 ? 2 : 1, nDecimals);
            return (
                <div className="tableCell">
                    <span className="delta">{pDelta === null || oppDelta === null ? "-" : this.state.statisticService.roundNumber(pDelta - oppDelta, nDecimals) + statChar}</span>
                </div>
            )
        }
    }

    private calcBpSaveDelta(playerNumber: number, statChar: string, nDecimals: number = 1) {
        if (this.props.avgResult !== undefined) {
            if (this.props.avgResult) {
                // eslint-disable-next-line
                const [pStat, pDelta] = this.getCalcStat(StatisticTypes.Bp_Save_Avg, StatisticMethod.AVERAGE, playerNumber <= 1 ? 1 : 2, nDecimals);
                // eslint-disable-next-line
                const [oppStat, oppDelta] = this.getCalcStat(StatisticTypes.Bp_Convert_Avg, StatisticMethod.AVERAGE, playerNumber <= 1 ? 2 : 1, nDecimals);
                return (
                    <div className="tableCell">
                        <span className="delta">{pDelta === null || oppDelta === null ? "-" : this.state.statisticService.roundNumber(pDelta - oppDelta, nDecimals) + statChar}</span>
                    </div>
                )
            }
        }
    }

    private calcHoldDelta(playerNumber: number, statChar: string, nDecimals: number = 1) {
        if (this.props.avgResult !== undefined) {
            if (this.props.avgResult) {
                // eslint-disable-next-line
                const [pStat, pDelta] = this.getCalcStat(StatisticTypes.Hold_Avg, StatisticMethod.AVERAGE, playerNumber <= 1 ? 1 : 2, nDecimals);
                // eslint-disable-next-line
                const [oppStat, oppDelta] = this.getCalcStat(StatisticTypes.Break_Avg, StatisticMethod.AVERAGE, playerNumber <= 1 ? 2 : 1, nDecimals);
                return (
                    <div className="tableCell">
                        <span className="delta">{pDelta === null || oppDelta === null ? "-" : this.state.statisticService.roundNumber(pDelta - oppDelta, nDecimals) + statChar}</span>
                    </div>
                )
            }
        }
    }

    private displayDeltas() {
        if (this.props.p1Error || this.props.p2Error || this.props.avgError) {
            return <TcErrorScreen tcError={this.props.p1Error || this.props.p2Error || this.props.avgError} />
        }
        else if (this.props.p1Results !== undefined && this.props.p2Results !== undefined && this.props.avgResult !== undefined && !this.props.p1Loading && !this.props.p2Loading && !this.props.avgLoading) {
            return (
                <React.Fragment>
                    <div className="tableContainer">
                        <div className="tableHeader">
                            <div className="tableCell">
                                <span>Stats</span>
                            </div>
                            <div className="tableCell">
                                <span>{this.props.p1Results.lastName}</span>
                            </div>
                            <div className="tableCell">
                                <span>{this.props.p2Results.lastName}</span>
                            </div>
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>Predict.</span>
                            </div>
                            <div className="tableCell">
                                <span>-</span>
                            </div>
                            <div className="tableCell">
                                <span>-</span>
                            </div>
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>Serve Hold Delta</span>
                            </div>
                            {this.calcHoldDelta(1, '%', 2)}
                            {this.calcHoldDelta(2, '%', 2)}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>Bp Faced/Gm Delta</span>
                            </div>
                            {this.calcBpFacedDelta(1, '', 2)}
                            {this.calcBpFacedDelta(2, '', 2)}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>Bp Save% Delta</span>
                            </div>
                            {this.calcBpSaveDelta(1, '%', 2)}
                            {this.calcBpSaveDelta(2, '%', 2)}
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        else {
            return <TcLoadingScreen />
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="tcSection matchPointsModel">
                    <h2>Point model</h2>
                    {this.displayDeltas()}
                </section>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        p1Results: state.match.p1Results,
        p2Results: state.match.p2Results,

        p1Loading: state.match.p1Loading,
        p2Loading: state.match.p2Loading,

        p1Error: state.match.p1Error,
        p2Error: state.match.p2Error,

        avgResult: state.match.avgResult,
        avgError: state.match.avgError,
        avgLoading: state.match.avgLoading,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchPointModelComp);
