import React from "react";
import { IPlayerInfo, IPlayerNotes } from "tennisconcrete-node";
import { getClassNames } from "../scripts/mapFunctions";
import TcButton from "./TcButton";
import TcTextarea from "./TcTextarea";

interface IClassProps {
    player: IPlayerInfo;
    notes: IPlayerNotes;
    notesError?: string;
    notesLoading: boolean;
    tcClass?: string;

    putNotesAct: (playerId: number, notes: string) => any;
}

interface IClassState {
    saveButtonDisabled: boolean;
    notesInput?: string;

    // For handling any state change
    [input: number]: any;
}

export default class TcNoteSection extends React.Component<IClassProps, IClassState> {

    constructor(props: IClassProps) {
        super(props);
        this.state = {
            saveButtonDisabled: false,
            notesInput: props.notes.notes,
        }
    }

    componentWillUnmount() {
        this.setState({
            notesInput: undefined
        })
    }

    private saveNotes() {
        if (this.state.notesInput !== undefined) {
            this.props.putNotesAct(this.props.player.playerId, this.state.notesInput);
            this.setState({
                saveButtonDisabled: true
            });
            setTimeout(() => {
                this.setState({
                    saveButtonDisabled: false
                })
            }, 1000);
        }
    }

    private saveButtonDisabled(): boolean {
        const notesLoading = this.props.notesLoading;
        const notesError = this.props.notesError !== undefined;
        const bttnDisabled = this.state.saveButtonDisabled;
        return notesLoading || notesError || bttnDisabled;
    }

    private onChange(e: any) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    private notesSaved(): boolean {
        return this.props.notes?.notes === this.state.notesInput
    }

    render() {
        return (
            <div className={`tcNoteSection${getClassNames(this.props.tcClass)}`}>
                <TcTextarea tcClass="blue" tcMinRows={3} tcName="notesInput" tcValue={this.state.notesInput} tcOnChange={(e: any) => this.onChange(e)} tcPlaceholder="Write your player notes here!" />
                <TcButton tcDisabled={this.saveButtonDisabled()} tcOnClick={() => this.saveNotes()} tcClass={`blue ${!this.notesSaved() ? 'unsaved' : ''}`}>
                    <span>Save {this.props.player.lastName} Notes{!this.notesSaved() ? " (Unsaved)" : ""}</span>
                </TcButton>
            </div>
        )
    }

}
