import { IQueueItem, ITournamentInfo } from "tennisconcrete-node";
import { postHttpRequest, getHttpRequest } from "../../scripts/HttpService";

export enum AdminActionTypes {
    SET_LOG = "SET_LOG_ADMIN",
    LOADING_LOG = "LOADING_LOG_ADMIN",
    ERROR_LOG = "ERROR_LOG_ADMIN",

    SET_TOURNEYS = "SET_TOURNEYS_ADMIN",
    LOADING_TOURNEYS = "LOADING_TOURNEYS_ADMIN",
    ERROR_TOURNEYS = "ERROR_TOURNEYS_ADMIN",

    ERROR_POST = "ERROR_POST_ADMIN",

    SET_USERINPUT_YEAR = "SET_USERINPUT_YEAR_ADMIN",
    SET_USERINPUT_TOUR = "SET_USERINPUT_TOUR_ADMIN"
}

export function setLogAct(log: IQueueItem[]) {
    return {
        type: AdminActionTypes.SET_LOG,
        log: log
    }
}

export function loadingLogAct(loading: boolean) {
    return {
        type: AdminActionTypes.LOADING_LOG,
        loading: loading
    }
}

export function errorLogAct(error: string) {
    return {
        type: AdminActionTypes.ERROR_LOG,
        error: error
    }
}

export function setTourneysAdminAct(tournaments: ITournamentInfo[]) {
    return {
        type: AdminActionTypes.SET_TOURNEYS,
        tournaments: tournaments
    }
}

export function loadingTourneysAdminAct(loading: boolean) {
    return {
        type: AdminActionTypes.LOADING_TOURNEYS,
        loading: loading
    }
}

export function errorTourneysAdminAct(error: string) {
    return {
        type: AdminActionTypes.ERROR_TOURNEYS,
        error: error
    }
}

export function errorPostAct(error: string) {
    return {
        type: AdminActionTypes.ERROR_POST,
        error: error
    }
}

// Interface inputs
export function setUserInputYearAct(year: number) {
    return {
        type: AdminActionTypes.SET_USERINPUT_YEAR,
        year: year
    }
}
export function setUserInputTourAct(tour: string) {
    return {
        type: AdminActionTypes.SET_USERINPUT_TOUR,
        tour: tour
    }
}


// Getters
export function fetchLogAct() {
    return getHttpRequest(`/api/queue`, setLogAct, errorLogAct, loadingLogAct);
}
export function getTournamentsAdminAct(year: number) {
    return getHttpRequest(`/api/tournaments/${year}`, setTourneysAdminAct, errorTourneysAdminAct, loadingTourneysAdminAct);
}

// Posters
export function postFetchScheduleAct() {
    return postHttpRequest(`/api/queue/schedule`, {}, undefined, errorPostAct, undefined);
}
export function postCalcAverageAct() {
    return postHttpRequest(`/api/queue/average`, {}, undefined, errorPostAct, undefined);
}
export function postFetchTournsAct(year: number) {
    return postHttpRequest(`/api/queue/tournaments/${year}`, {}, undefined, errorPostAct, undefined);
}
export function postFetchTournAct(tournId: number) {
    return postHttpRequest(`/api/queue/tournament/${tournId}`, {}, undefined, errorPostAct, undefined);
}