import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { IAuthtoken } from 'tennisconcrete-node';
import TcButton from '../../components/TcButton';
import TcInput from '../../components/TcInput';
import TcMain from '../../components/TcMain';
import { postLoginAct } from '../../redux/actions/authActions';

import './LoginView.scss';

interface IClassProps {
    location?: any;
    history?: any;

    authtoken?: IAuthtoken;
    authLoading?: boolean;
    authError?: string;

    postLoginAct: (username: string, password: string) => any;
}

interface IClassState {
    inputUsername: string;
    inputPassword: string;
    [input: string]: any;
}

class LoginView extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
            inputUsername: "",
            inputPassword: ""
        }
    }

    onInput(e: any) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    onClickLogin(e: any) {
        e.preventDefault();
        this.props.postLoginAct(this.state.inputUsername, this.state.inputPassword);
    }

    loginBttnDisabled() {
        return this.state.inputPassword === "" || this.state.inputUsername === ""
    }

    render() {
        if (this.props.authtoken !== undefined && this.props.authtoken.activated === true) return <Redirect to="/schedule" />
        else return (
            <TcMain tcClass="loginView">
                <section>
                    <div className="loginContainer">
                        <h1>Login</h1>
                        <TcInput tcType="text" tcPlaceholder="Username" tcName="inputUsername" tcLabel="Username" tcValue={this.state.inputUsername} tcOnChange={(e: any) => this.onInput(e)} />
                        <TcInput tcType="password" tcPlaceholder="Password" tcName="inputPassword" tcLabel="Password" tcValue={this.state.inputPassword} tcOnChange={(e: any) => this.onInput(e)} />
                        <TcButton tcDisabled={this.loginBttnDisabled()} tcOnClick={(e: any) => this.onClickLogin(e)}>Login</TcButton>
                    </div>
                </section>
            </TcMain>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        authtoken: state.auth.authtoken,
        authError: state.auth.error,
        authLoading: state.auth.loading
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        postLoginAct: (username: string, password: string) => dispatch(postLoginAct(username, password))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
