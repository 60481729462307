import React from 'react';
import { connect } from 'react-redux';
import { IPlayer, StatisticService, StatisticTypes, ITourAverage } from 'tennisconcrete-node';
import TcErrorScreen from '../../components/TcErrorScreen';
import TcLoadingScreen from '../../components/TcLoadingScreen';

interface IClassProps {
    p1Results: IPlayer;
    p2Results: IPlayer;

    p1Loading: boolean;
    p2Loading: boolean;

    p1Error?: string;
    p2Error?: string;

    avgResult?: ITourAverage | null;
    avgError?: string,
    avgLoading: boolean,
}

interface IClassState {
    statisticService: StatisticService;
    sectionHidden: boolean;
}

enum StatisticMethod {
    AVERAGE = "AVERAGE",
    MEDIAN = "MEDIAN",
    TOTAL = "TOTAL"
}

class MatchStatsComp extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
            statisticService: new StatisticService(),
            sectionHidden: false
        }
    }

    private getCalcStat(stat: StatisticTypes, method: StatisticMethod, playerNumber: number, nDecimals: number = 1): any {
        if (this.props.avgResult !== undefined) {

            const playerResults = playerNumber === 1 ? this.props.p1Results : this.props.p2Results;
            const tourAvg: number | null = this.props.avgResult === null ? null : this.state.statisticService.getAverageStatistic(this.props.avgResult, stat);
            let playerStatistic: number | null;

            if (method === StatisticMethod.AVERAGE) {
                playerStatistic = this.state.statisticService.averageStatPlayer(playerResults.playerId, playerResults.results, stat, null, nDecimals);
            } else if (method === StatisticMethod.MEDIAN) {
                playerStatistic = this.state.statisticService.medianStat(playerResults.playerId, playerResults.results, stat, null);
            } else {
                playerStatistic = this.state.statisticService.additionStat(playerResults.playerId, playerResults.results, stat, null);
            }
            return [playerStatistic, tourAvg === null || playerStatistic === null ? null : this.state.statisticService.roundNumber(playerStatistic - tourAvg, nDecimals)];
        } else {
            return [null, null]
        }
    }

    private calcAvgStat(stat: StatisticTypes, method: StatisticMethod, playerNumber: number, statChar: string, nDecimals: number = 1) {
        if (this.props.avgResult !== undefined) {
            const [pStat, pDelta] = this.getCalcStat(stat, method, playerNumber, nDecimals);
            return (
                <div className="tableCell">
                    <span className="delta">{pStat === null ? "-" : pDelta + statChar}</span>
                    {pStat !== null && <span className="percentage">{pStat + statChar}</span>}
                </div>
            )
        }
    }

    private toggleHidden() {
        this.setState({
            sectionHidden: !this.state.sectionHidden
        })
    }

    private displayStats() {
        if (this.props.p1Error || this.props.p2Error || this.props.avgError) {
            return <TcErrorScreen tcError={this.props.p1Error || this.props.p2Error || this.props.avgError} />
        }
        else if (this.props.p1Results !== undefined && this.props.p2Results !== undefined && this.props.avgResult !== undefined && !this.props.p1Loading && !this.props.p2Loading && !this.props.avgLoading) {
            return (
                <React.Fragment>
                    <div className="tableContainer">
                        <div className="tableHeader">
                            <div className="tableCell">
                                <span>Serve</span>
                            </div>
                            <div className="tableCell">
                                <span>{this.props.p1Results.lastName}</span>
                            </div>
                            <div className="tableCell">
                                <span>{this.props.p2Results.lastName}</span>
                            </div>
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>Serve Hold</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.Hold_Avg, StatisticMethod.AVERAGE, 1, '%')}
                            {this.calcAvgStat(StatisticTypes.Hold_Avg, StatisticMethod.AVERAGE, 2, '%')}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>Serve Break</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.Break_Avg, StatisticMethod.AVERAGE, 1, '%')}
                            {this.calcAvgStat(StatisticTypes.Break_Avg, StatisticMethod.AVERAGE, 2, '%')}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>1st In</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.FirstServeIn_Avg, StatisticMethod.AVERAGE, 1, '%')}
                            {this.calcAvgStat(StatisticTypes.FirstServeIn_Avg, StatisticMethod.AVERAGE, 2, '%')}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>1st%</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.FirstServe_Avg, StatisticMethod.AVERAGE, 1, '%')}
                            {this.calcAvgStat(StatisticTypes.FirstServe_Avg, StatisticMethod.AVERAGE, 2, '%')}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>2st%</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.SecondServe_Avg, StatisticMethod.AVERAGE, 1, '%')}
                            {this.calcAvgStat(StatisticTypes.SecondServe_Avg, StatisticMethod.AVERAGE, 2, '%')}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>SPW</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.SPW_Avg, StatisticMethod.AVERAGE, 1, '%')}
                            {this.calcAvgStat(StatisticTypes.SPW_Avg, StatisticMethod.AVERAGE, 2, '%')}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>RPW</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.RPW_Avg, StatisticMethod.AVERAGE, 1, '%')}
                            {this.calcAvgStat(StatisticTypes.RPW_Avg, StatisticMethod.AVERAGE, 2, '%')}
                        </div>
                    </div>
                    <div className="tableContainer">
                        <div className="tableHeader">
                            <div className="tableCell">
                                <span>Break</span>
                            </div>
                            <div className="tableCell">
                                <span>{this.props.p1Results.lastName}</span>
                            </div>
                            <div className="tableCell">
                                <span>{this.props.p2Results.lastName}</span>
                            </div>
                        </div>


                        <div className="tableRow">
                            <div className="tableCell">
                                <span>Bp Faced/GM</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.Bp_Faced_Game_Ratio, StatisticMethod.AVERAGE, 1, '', 2)}
                            {this.calcAvgStat(StatisticTypes.Bp_Faced_Game_Ratio, StatisticMethod.AVERAGE, 2, '', 2)}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>Bp Save%</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.Bp_Save_Avg, StatisticMethod.AVERAGE, 1, '%')}
                            {this.calcAvgStat(StatisticTypes.Bp_Save_Avg, StatisticMethod.AVERAGE, 2, '%')}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>Bp Chances/GM</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.Bp_Chances_Game_Ratio, StatisticMethod.AVERAGE, 1, '', 2)}
                            {this.calcAvgStat(StatisticTypes.Bp_Chances_Game_Ratio, StatisticMethod.AVERAGE, 2, '', 2)}
                        </div>
                        <div className="tableRow">
                            <div className="tableCell">
                                <span>Bp Convert%</span>
                            </div>
                            {this.calcAvgStat(StatisticTypes.Bp_Convert_Avg, StatisticMethod.AVERAGE, 1, '%')}
                            {this.calcAvgStat(StatisticTypes.Bp_Convert_Avg, StatisticMethod.AVERAGE, 2, '%')}
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        else {
            return <TcLoadingScreen />
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className={`tcSection matchPointsModel ${this.state.sectionHidden ? 'hidden' : ''}`}>
                    <h2 onClick={() => this.toggleHidden()}>
                        Statistics
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 492 492" xmlSpace="preserve">
                            <path d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
			c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
			L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
			c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
			c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
			C492,219.198,479.172,207.418,464.344,207.418z"/>
                        </svg>
                    </h2>
                    {this.displayStats()}
                </section>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        p1Results: state.match.p1Results,
        p2Results: state.match.p2Results,

        p1Loading: state.match.p1Loading,
        p2Loading: state.match.p2Loading,

        p1Error: state.match.p1Error,
        p2Error: state.match.p2Error,

        avgResult: state.match.avgResult,
        avgError: state.match.avgError,
        avgLoading: state.match.avgLoading,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchStatsComp);
