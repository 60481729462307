import { FilterTypes, ITourAverage, IPlayer, IPlayerNotes } from "tennisconcrete-node";
import { getHttpRequest, putHttpRequest } from "../../scripts/HttpService";

export enum MatchActionTypes {
    SET_P1_RESULTS = "SET_P1_RESULTS_MATCH",
    SET_P2_RESULTS = "SET_P2_RESULTS_MATCH",

    SET_P1_NOTES = "SET_P1_NOTES_MATCH",
    SET_P2_NOTES = "SET_P2_NOTES_MATCH",

    SET_P1_NOTES_ERROR = "SET_P1_NOTES_ERROR_MATCH",
    SET_P2_NOTES_ERROR = "SET_P2_NOTES_ERROR_MATCH",

    SET_P1_ERROR = "SET_P1_ERROR_MATCH",
    SET_P2_ERROR = "SET_P2_ERROR_MATCH",

    SET_P1_LOADING = "SET_P1_LOADING_MATCH",
    SET_P2_LOADING = "SET_P2_LOADING_MATCH",

    SET_AVG = "SET_AVG_MATCH",
    SET_AVG_LOADING = "SET_AVG_LOADING_MATCH",
    SET_AVG_ERROR = "SET_AVG_ERROR_MATCH",

    SET_MODEL = "SET_MODEL_MATCH",
    SET_MODEL_LOADING = "SET_MODEL_LOADING_MATCH",
    SET_MODEL_ERROR = "SET_MODEL_ERROR_MATCH"
}

export function setModelResult(model: ITourAverage) {
    return {
        type: MatchActionTypes.SET_MODEL,
        model: model
    }
}

export function setModelLoading(loading: boolean) {
    return {
        type: MatchActionTypes.SET_MODEL_LOADING,
        loading: loading
    }
}

export function setModelError(error: string) {
    return {
        type: MatchActionTypes.SET_MODEL_ERROR,
        error: error
    }
}

export function setP1Results(results: IPlayer) {
    return {
        type: MatchActionTypes.SET_P1_RESULTS,
        results: results
    }
}

export function setP2Results(results: IPlayer) {
    return {
        type: MatchActionTypes.SET_P2_RESULTS,
        results: results
    }
}

export function setP1Loading(loading: boolean) {
    return {
        type: MatchActionTypes.SET_P1_LOADING,
        loading: loading
    }
}

export function setP2Loading(loading: boolean) {
    return {
        type: MatchActionTypes.SET_P2_LOADING,
        loading: loading
    }
}


export function setP1Error(error: string) {
    return {
        type: MatchActionTypes.SET_P1_ERROR,
        error: error
    }
}

export function setP2Error(error: string) {
    return {
        type: MatchActionTypes.SET_P2_ERROR,
        error: error
    }
}

export function setAvgResult(average: ITourAverage) {
    return {
        type: MatchActionTypes.SET_AVG,
        average: average
    }
}

export function setAvgLoading(loading: boolean) {
    return {
        type: MatchActionTypes.SET_AVG_LOADING,
        loading: loading
    }
}

export function setAvgError(error: string) {
    return {
        type: MatchActionTypes.SET_AVG_ERROR,
        error: error
    }
}

export function setP1Notes(notes: IPlayerNotes) {
    return {
        type: MatchActionTypes.SET_P1_NOTES,
        notes: notes
    }
}

export function setP2Notes(notes: IPlayerNotes) {
    return {
        type: MatchActionTypes.SET_P2_NOTES,
        notes: notes
    }
}

export function setP1NotesError(error: string) {
    return {
        type: MatchActionTypes.SET_P1_NOTES_ERROR,
        error: error
    }
}

export function setP2NotesError(error: string) {
    return {
        type: MatchActionTypes.SET_P2_NOTES_ERROR,
        error: error
    }
}

export function getP1Results(playerId: string, filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) {
    return getHttpRequest(`/api/player/${playerId}?filterType=${filterType}&filterValue=${filterValue}&surface=${surfaces.join(',')}&tour=${tours.join(',')}`, setP1Results, setP1Error, setP1Loading);
}

export function getP2Results(playerId: string, filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) {
    return getHttpRequest(`/api/player/${playerId}?filterType=${filterType}&filterValue=${filterValue}&surface=${surfaces.join(',')}&tour=${tours.join(',')}`, setP2Results, setP2Error, setP2Loading);
}

export function getP1Notes(playerId: number) {
    return getHttpRequest(`/api/player/${playerId}/notes`, setP1Notes, setP1NotesError, setP1Loading);
}

export function getP2Notes(playerId: number) {
    return getHttpRequest(`/api/player/${playerId}/notes`, setP2Notes, setP2NotesError, setP2Loading);
}

export function putP1Notes(playerId: number, notes: string) {
    return putHttpRequest(`/api/player/${playerId}/notes`, { notes: notes }, setP1Notes, setP1NotesError, setP1Loading);
}

export function putP2Notes(playerId: number, notes: string) {
    return putHttpRequest(`/api/player/${playerId}/notes`, { notes: notes }, setP2Notes, setP2NotesError, setP2Loading);
}

export function getAvgResults(filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) {
    return getHttpRequest(`/api/tour/average?filterType=${filterType}&filterValue=${filterValue}&surface=${surfaces.join(',')}&tour=${tours.join(',')}`, setAvgResult, setAvgError, setAvgLoading);
}


export function getSimulation(p1Id: number, p2Id: number, surfaces: string, tours: string, odds1: number, odds2: number) {
    return getHttpRequest(`/api/model?p1Id=${p1Id}&p2Id=${p2Id}&surface=${surfaces}&tour=${tours}&odds1=${odds1}&odds2=${odds2}`, setModelResult, setModelError, setModelLoading);
}