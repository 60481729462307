import { FilterTypes, IPlayer, IPlayerNotes } from "tennisconcrete-node";
import { getHttpRequest, putHttpRequest } from "../../scripts/HttpService";

export enum PlayerActionTypes {
    SET_PLAYER_RESULTS = "SET_PLAYER_RESULTS_MATCH",
    SET_PLAYER_ERROR = "SET_PLAYER_ERROR_MATCH",
    SET_PLAYER_LOADING = "SET_PLAYER_LOADING_MATCH",

    SET_PLAYER_NOTES = "SET_PLAYER_NOTES_MATCH",
    SET_PLAYER_NOTES_ERROR = "SET_PLAYER_NOTES_ERROR_MATCH",
    SET_PLAYER_NOTES_LOADING = "SET_PLAYER_NOTES_LOADING_MATCH",
}

export function setPlayerAct(player: IPlayer) {
    return {
        type: PlayerActionTypes.SET_PLAYER_RESULTS,
        player: player
    }
}

export function setPlayerErrorAct(error: string) {
    return {
        type: PlayerActionTypes.SET_PLAYER_ERROR,
        error: error
    }
}

export function setPlayerLoadingAct(loading: boolean) {
    return {
        type: PlayerActionTypes.SET_PLAYER_LOADING,
        loading: loading
    }
}


export function setPlayerNotesAct(notes: IPlayerNotes) {
    return {
        type: PlayerActionTypes.SET_PLAYER_NOTES,
        notes: notes
    }
}

export function setPlayerNotesErrorAct(error: string) {
    return {
        type: PlayerActionTypes.SET_PLAYER_NOTES_ERROR,
        error: error
    }
}

export function setPlayerNotesLoadingAct(loading: boolean) {
    return {
        type: PlayerActionTypes.SET_PLAYER_NOTES_LOADING,
        loading: loading
    }
}

export function getPlayerAct(playerId: number, filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) {
    return getHttpRequest(`/api/player/${playerId}?filterType=${filterType}&filterValue=${filterValue}&surface=${surfaces.join(',')}&tour=${tours.join(',')}`, setPlayerAct, setPlayerErrorAct, setPlayerLoadingAct);
}

export function getPlayerNotesAct(playerId: number) {
    return getHttpRequest(`/api/player/${playerId}/notes`, setPlayerNotesAct, setPlayerNotesErrorAct, setPlayerNotesLoadingAct);
}

export function putPlayerNotesAct(playerId: number, notes: string) {
    return putHttpRequest(`/api/player/${playerId}/notes`, { notes: notes }, setPlayerNotesAct, setPlayerNotesErrorAct, setPlayerNotesLoadingAct);
}
