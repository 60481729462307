import { IAuthtoken } from "tennisconcrete-node";
import { jsonDateParser } from "./mapFunctions";
import store from "./store";

enum methodTypes {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
};

export function getHttpRequest(url: string, successAction?: { (body: any): void }, errorAction?: { (body: any): void }, loadingAction?: { (body: any): void }) {
    return async (dispatch: (arg0: void) => void) => {
        if (loadingAction) dispatch(loadingAction(true));
        try {
            const options: any = {
                method: methodTypes.GET,
                mode: 'cors',
                credentials: 'include'
            };

            const authtoken: IAuthtoken | undefined = store.getState().auth.authtoken;
            if (authtoken !== undefined) options.headers = new Headers({ Authorization: authtoken.authtoken });

            const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);
            let body = await response.json();
            body = JSON.parse(JSON.stringify(body), jsonDateParser);
            if (response.ok && successAction) dispatch(successAction(body));
            else if (!response.ok && errorAction) dispatch(errorAction(body.error));
        } catch (err) {
            if (errorAction) dispatch(errorAction('Internal server error!'));
        }
        if (loadingAction) dispatch(loadingAction(false));
    };
}

export function postHttpRequest(url: string, httpBody: object, successAction?: { (body: any): void }, errorAction?: { (body: any): void }, loadingAction?: { (body: any): void }) {
    return async (dispatch: (arg0: void) => void) => {
        if (loadingAction) dispatch(loadingAction(true));
        try {
            const headers: any = { 'Content-Type': 'application/json' };

            const authtoken: IAuthtoken | undefined = store.getState().auth.authtoken;
            if (authtoken !== undefined) headers.Authorization = authtoken.authtoken;

            const options: object = {
                method: methodTypes.POST,
                mode: 'cors',
                credentials: 'include',
                headers: new Headers(headers),
                body: JSON.stringify(httpBody)
            };

            const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);
            let body = await response.json();
            body = JSON.parse(JSON.stringify(body), jsonDateParser);
            if (response.ok && successAction) dispatch(successAction(body));
            else if (!response.ok && errorAction) dispatch(errorAction(body.error));
        } catch (err) {
            if (errorAction) dispatch(errorAction('Internal server error!'));
        }
        if (loadingAction) dispatch(loadingAction(false));
    };
}

export function putHttpRequest(url: string, httpBody: object, successAction?: { (body: any): void }, errorAction?: { (body: any): void }, loadingAction?: { (body: any): void }) {
    return async (dispatch: (arg0: void) => void) => {
        if (loadingAction) dispatch(loadingAction(true));
        try {
            const headers: any = { 'Content-Type': 'application/json' };

            const authtoken: IAuthtoken | undefined = store.getState().auth.authtoken;
            if (authtoken !== undefined) headers.Authorization = authtoken.authtoken;

            const options: object = {
                method: methodTypes.PUT,
                mode: 'cors',
                credentials: 'include',
                headers: new Headers(headers),
                body: JSON.stringify(httpBody)
            };

            const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);
            let body = await response.json();
            body = JSON.parse(JSON.stringify(body), jsonDateParser);
            if (response.ok && successAction) dispatch(successAction(body));
            else if (!response.ok && errorAction) dispatch(errorAction(body.error));
        } catch (err) {
            if (errorAction) dispatch(errorAction('Internal server error!'));
        }
        if (loadingAction) dispatch(loadingAction(false));
    };
}

export function deleteHttpRequest(url: string, successAction?: { (body: any): void }, errorAction?: { (body: any): void }, loadingAction?: { (body: any): void }) {
    return async (dispatch: (arg0: void) => void) => {
        if (loadingAction) dispatch(loadingAction(true));
        try {
            const options: any = {
                method: methodTypes.DELETE,
                mode: 'cors',
                credentials: 'include'
            };

            const authtoken: IAuthtoken | undefined = store.getState().auth.authtoken;
            if (authtoken !== undefined) options.headers = new Headers({ Authorization: authtoken.authtoken });

            const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);
            let body = await response.json();
            body = JSON.parse(JSON.stringify(body), jsonDateParser);
            if (response.ok && successAction) dispatch(successAction(body));
            else if (!response.ok && errorAction) dispatch(errorAction(body.error));
        } catch (err) {
            if (errorAction) dispatch(errorAction('Internal server error!'));
        }
        if (loadingAction) dispatch(loadingAction(false));
    };
}
