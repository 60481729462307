import { combineReducers } from 'redux';
import adminReducer from './adminReducer';
import authReducer from './authReducer';
import matchReducer from './matchReducer';
import playersReducer from './playersReducer';
import scheduleReducer from './scheduleReducer';
import playerReducer from './playerReducer';
import tournamentReducer from './tournamentReducer';

const rootReducer = combineReducers({
    schedule: scheduleReducer,
    admin: adminReducer,
    match: matchReducer,
    auth: authReducer,
    players: playersReducer,
    player: playerReducer,
    tournament: tournamentReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;