import React from 'react';
import { Redirect, Route } from 'react-router';
import { connect } from 'react-redux';
import { IAuthtoken } from 'tennisconcrete-node';

interface IClassProps {
    authtoken: IAuthtoken;
    exact: boolean;
    path: string;
    component?: any;
    children?: any;
}

interface IClassState {
}

class TcPrivateRoute extends React.Component<IClassProps, IClassState> {
    render() {
        if (this.props.authtoken === undefined || (this.props.authtoken !== undefined && this.props.authtoken.activated === false)) {
            return <Redirect to='/login' />
        } else {
            return <Route exact={this.props.exact || false} path={this.props.path} component={this.props.component}>{this.props.children}</Route>;
        }
    }
}

function mapStateToProps(state: any) {
    return {
        authtoken: state.auth.authtoken
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TcPrivateRoute);
