import React from 'react';
import { ISelectOption } from '../entities/ISelectOption';
import { getClassNames } from '../scripts/mapFunctions';

interface IProps {
    tcOptions?: ISelectOption[];
    tcOnChange?: any;
    tcDisabled?: boolean;
    tcPlaceholder?: string;
    tcId?: string;
    tcName?: string;
    tcClass?: string;
}

export default function TcSelect(props: IProps) {

    // expect object array like: [ { value: "string", text: "string"} ]
    const getOptions = () => {
        return props.tcOptions && props.tcOptions.length > 0
            ? props.tcOptions.map((option: any, index: number) => (
                <option key={`tcSelectOption-${option.value}-${index}`} value={option.value}>
                    {option.text}
                </option>
            ))
            : null;
    }

    const tcOnChange = (e: any) => {
        if (props.tcOnChange) props.tcOnChange(e);
    };

    return (
        <select
            disabled={props.tcDisabled}
            defaultValue={`${props.tcPlaceholder}`}
            onChange={(e) => tcOnChange(e)}
            id={props.tcId}
            name={props.tcName}
            className={`tcSelect${getClassNames(props.tcClass)}`}
        >
            {getOptions()}
        </select>
    );
}
