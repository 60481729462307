import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITournament } from "tennisconcrete-node";

interface IAdminReducer {
    tournament?: ITournament;
    tournamentLoading: boolean;
    tournamentError?: string;
}

const initialState: IAdminReducer = {
    tournament: undefined,
    tournamentLoading: false,
    tournamentError: undefined,
};

const slice = createSlice({
    name: "tournament",
    initialState: initialState,
    reducers: {

        setTournament: (state, action: PayloadAction<ITournament>) => {
            return { ...state, tournament: action.payload };
        },
        setTournamentLoading: (state, action: PayloadAction<boolean>) => {
            return { ...state, tournamentLoading: action.payload };
        },
        setTournamentError: (state, action: PayloadAction<string | undefined>) => {
            return { ...state, tournamentError: action.payload };
        },

    }
});

export const { setTournament, setTournamentError, setTournamentLoading } = slice.actions;
export default slice.reducer;