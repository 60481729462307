import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ESurfaces, IMatch, IPlayer, ITournament } from 'tennisconcrete-node';
import TcButton from '../../components/TcButton';
import TcErrorScreen from '../../components/TcErrorScreen';
import TcLoadingScreen from '../../components/TcLoadingScreen';

import { getDateFromISO, getMatchScore } from '../../scripts/mapFunctions';

interface IClassProps {
    p1Results: IPlayer;
    p2Results: IPlayer;

    p1Loading: boolean;
    p2Loading: boolean;

    p1Error?: string;
    p2Error?: string;
}

interface IClassState {
    p1Active: boolean;
    p2Active: boolean;
}

class MatchHistoryComp extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
            p1Active: true,
            p2Active: false
        }
    }

    private setActiveResults(playerNr: number) {
        this.setState({
            p1Active: playerNr <= 1 ? true : false,
            p2Active: playerNr <= 1 ? false : true
        })
    }

    private getSurfaceClass(surface: ESurfaces) {
        switch (surface) {
            case ESurfaces.CLAY:
                return 'clay';
            case ESurfaces.GRASS:
                return 'grass';
            case ESurfaces.HARD:
                return 'hard';
            default:
                return 'indoor';
        }
    }

    private getResultsList(player: IPlayer) {
        return player.results.map((tourn: ITournament) => {
            const html = [];
            html.push(
                <div className="tableHeader" key={`historicHeader_${player.playerId}_${tourn.tournamentId}`}>
                    <div className="tableCell tournament">
                        <Link to={`/tournament/${tourn.tournamentId}`}>{tourn.name}</Link>
                    </div>
                    <div className="tableCell country">
                        <span>&nbsp;- {tourn.country.name}</span>
                    </div>
                </div>
            );
            html.push(...tourn.results.map((result: IMatch) => {
                const opponent = player.playerId === result.playerWinnerId ? result.playerB : result.playerA;
                const lostMatch: boolean = player.playerId === result.playerLoserId;
                return (
                    <div className='tableRow' key={`historicResult_${player.playerId}_${result.matchId}`}>
                        <div className="date">
                            <span>{getDateFromISO(result.date)}</span>
                        </div>
                        <div className="surface">
                            <div className={`surfaceImg ${this.getSurfaceClass(tourn.surface)}`}></div>
                        </div>
                        <div className="opponent">
                            <Link to={`/player/${opponent.playerId}`}>{opponent.initial}. {opponent.lastName}</Link>
                        </div>
                        <div className={`score${lostMatch ? ' lost' : ''}`}>
                            <span>{getMatchScore(result)}</span>
                        </div>
                    </div>
                )
            }));
            return <div className="tableContainer" key={`historicTourn_${player.playerId}_${tourn.tournamentId}`}>{html}</div>;
        });
    }

    private displayViewContent() {
        if (this.props.p1Error || this.props.p2Error) {
            return <TcErrorScreen tcError={this.props.p1Error || this.props.p2Error} />
        }
        else if (this.props.p1Results !== undefined && this.props.p2Results !== undefined) {
            return (
                <React.Fragment>
                    <div className="resultsHeader">
                        <TcButton tcClass={`historyBttn${this.state.p1Active ? ' enabled' : ''}`} tcOnClick={() => this.setActiveResults(1)}>
                            <span>{this.props.p1Results.lastName}</span>
                        </TcButton>
                        <TcButton tcClass={`historyBttn${this.state.p2Active ? ' enabled' : ''}`} tcOnClick={() => this.setActiveResults(2)}>
                            <span>{this.props.p2Results.lastName}</span>
                        </TcButton>
                    </div>
                    <div className={`results${this.state.p1Active ? ' enabled' : ''}`}>
                        {this.getResultsList(this.props.p1Results)}
                    </div>
                    <div className={`results${this.state.p2Active ? ' enabled' : ''}`}>
                        {this.getResultsList(this.props.p2Results)}
                    </div>
                </React.Fragment>
            )
        }
        else {
            return <TcLoadingScreen />
        }
    }

    render() {
        return (
            <section className="tcSection historyResults">
                <h2>Historic Results</h2>
                {this.displayViewContent()}
            </section>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        p1Results: state.match.p1Results,
        p2Results: state.match.p2Results,

        p1Loading: state.match.p1Loading,
        p2Loading: state.match.p2Loading,

        p1Error: state.match.p1Error,
        p2Error: state.match.p2Error,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchHistoryComp);
