import React from 'react';
import { connect } from 'react-redux';
import { IScheduledTournament } from 'tennisconcrete-node';
import TcMain from '../../components/TcMain';
import TcMainHeader from '../../components/TcMainHeader';
import { fetchScheduleAct } from '../../redux/actions/scheduleActions';
import ScheduleList from './ScheduleList';
import TcLoadingScreen from '../../components/TcLoadingScreen';

import './ScheduleView.scss';
import TcErrorScreen from '../../components/TcErrorScreen';

interface IScheduleProps {
    schedule: IScheduledTournament[],
    error: string,
    loading: boolean,
    fetchScheduleAct: () => any
}

class ScheduleView extends React.Component<IScheduleProps> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
        props.fetchScheduleAct();
    }

    displayViewContent() {
        if (this.props.error) {
            return <TcErrorScreen tcError={this.props.error} />
        }
        else if (this.props.schedule && !this.props.loading) {
            return <ScheduleList schedule={this.props.schedule} />
        }
        else {
            return <TcLoadingScreen />
        }
    }

    render() {
        return (
            <TcMain tcClass={["scheduleView"]}>
                <TcMainHeader tcHeader="Schedule" />
                {this.displayViewContent()}
            </TcMain>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        schedule: state.schedule.schedule,
        scheduleLoading: state.schedule.loading,
        scheduleError: state.schedule.error
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        fetchScheduleAct: () => dispatch(fetchScheduleAct())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleView);
