import React from 'react';
import { connect } from 'react-redux';
import { IPlayer, IPlayerNotes } from 'tennisconcrete-node';
import TcButton from '../../components/TcButton';
import TcErrorScreen from '../../components/TcErrorScreen';
import TcLoadingScreen from '../../components/TcLoadingScreen';
import TcNoteSection from '../../components/TcNoteSection';
import { getP1Notes, getP2Notes, putP1Notes, putP2Notes } from '../../redux/actions/matchActions';

interface IClassProps {
    location?: any;
    history?: any;

    p1Id: number;
    p2Id: number;

    p1Notes?: IPlayerNotes;
    p2Notes?: IPlayerNotes;

    player1?: IPlayer;
    player2?: IPlayer;

    p1NotesError?: string;
    p2NotesError?: string;

    p1NotesLoading: boolean;
    p2NotesLoading: boolean;

    getP1NotesAct: (playerId: number) => any;
    getP2NotesAct: (playerId: number) => any;
    putP1NotesAct: (playerId: number, notes: string) => any;
    putP2NotesAct: (playerId: number, notes: string) => any;
}

interface IClassState {
    p1Active: boolean;
    p2Active: boolean;
}

class MatchNotesComp extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
            p1Active: true,
            p2Active: false,
        }
    }

    // Initialize components
    public componentDidMount() {
        this.getNotes()
    }

    private getNotes() {
        this.props.getP1NotesAct(this.props.p1Id);
        this.props.getP2NotesAct(this.props.p2Id);
    }

    private setActiveResults(playerNr: number) {
        this.setState({
            p1Active: playerNr <= 1 ? true : false,
            p2Active: playerNr <= 1 ? false : true
        })
    }

    public correctPlayerInfo(): boolean {
        return this.props.player1 !== undefined && this.props.player2 !== undefined && this.props.p1Notes !== undefined && this.props.p2Notes !== undefined && this.props.p1Id === this.props.p1Notes.playerId && this.props.p2Id === this.props.p2Notes.playerId && this.props.p1Id === this.props.player1.playerId && this.props.p2Id === this.props.player2.playerId
    }

    private displayViewContent() {
        if (this.props.p1NotesError || this.props.p2NotesError) {
            return <TcErrorScreen tcError={this.props.p1NotesError || this.props.p2NotesError} />
        }
        else if (this.correctPlayerInfo()) {
            return (
                <React.Fragment>
                    <div className="notesHeader">
                        <TcButton tcClass={`switchBttn${this.state.p1Active ? ' enabled' : ''}`} tcOnClick={() => this.setActiveResults(1)}>
                            <span>{this.props.player1!.lastName}</span>
                        </TcButton>
                        <TcButton tcClass={`switchBttn${this.state.p2Active ? ' enabled' : ''}`} tcOnClick={() => this.setActiveResults(2)}>
                            <span>{this.props.player2!.lastName}</span>
                        </TcButton>
                    </div>
                    <TcNoteSection tcClass={`notesContainer${this.state.p1Active ? ' enabled' : ''}`}
                        player={this.props.player1!}
                        putNotesAct={(playerId: number, notes: string) => this.props.putP1NotesAct(playerId, notes)}
                        notes={this.props.p1Notes!}
                        notesError={this.props.p1NotesError}
                        notesLoading={this.props.p1NotesLoading}
                    />

                    <TcNoteSection tcClass={`notesContainer${this.state.p2Active ? ' enabled' : ''}`}
                        player={this.props.player2!}
                        putNotesAct={(playerId: number, notes: string) => this.props.putP2NotesAct(playerId, notes)}
                        notes={this.props.p2Notes!}
                        notesError={this.props.p2NotesError}
                        notesLoading={this.props.p2NotesLoading}
                    />

                </React.Fragment>
            )
        }
        else {
            return <TcLoadingScreen />
        }
    }

    render() {
        return (
            <section className="tcSection playerNotes">
                <h2>Player notes</h2>
                {this.displayViewContent()}
            </section>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        player1: state.match.p1Results,
        player2: state.match.p2Results,

        p1NotesError: state.match.p1NotesError,
        p2NotesError: state.match.p2NotesError,

        p1NotesLoading: state.match.p1NotesLoading,
        p2NotesLoading: state.match.p2NotesLoading,

        p1Notes: state.match.p1Notes,
        p2Notes: state.match.p2Notes
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getP1NotesAct: (playerId: number) => dispatch(getP1Notes(playerId)),
        getP2NotesAct: (playerId: number) => dispatch(getP2Notes(playerId)),
        putP1NotesAct: (playerId: number, notes: string) => dispatch(putP1Notes(playerId, notes)),
        putP2NotesAct: (playerId: number, notes: string) => dispatch(putP2Notes(playerId, notes)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchNotesComp);
