import React from 'react';
import { connect } from 'react-redux';
import { IModelResult, IPlayer, parseNumber, StatisticService, tryParseNumber } from 'tennisconcrete-node';
import TcButton from '../../components/TcButton';
import TcErrorScreen from '../../components/TcErrorScreen';
import TcInput from '../../components/TcInput';
import TcLoadingScreen from '../../components/TcLoadingScreen';
import TcSelect from '../../components/TcSelect';
import { ISelectOption } from '../../entities/ISelectOption';
import { getSimulation } from '../../redux/actions/matchActions';

interface IClassProps {
    location?: any;
    history?: any;

    p1Id: number;
    p2Id: number;

    player1?: IPlayer;
    player2?: IPlayer;

    p1Loading: boolean;
    p2Loading: boolean;

    p1Error?: string;
    p2Error?: string;

    model?: IModelResult;
    modelLoading: boolean;
    modelError?: string

    getModelPrediction: (p1Id: number, p2Id: number, surfaces: string, tours: string, odds1: number, odds2: number) => any;
}

interface IClassState {
    p1Active: boolean;
    p2Active: boolean;

    odds1: string;
    odds2: string;

    selectedTour: string;
    selectedSurface: string;

    // For handling any state change
    [input: number]: any;
}

class MatchModelComp extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
            p1Active: true,
            p2Active: false,
            odds1: '',
            odds2: '',
            selectedTour: this.tourOptions[0].value,
            selectedSurface: this.surfaceOptions[0].value,
        }
    }

    private tourOptions: ISelectOption[] = [
        {
            text: "WTA",
            value: "wta,itf"
        }
    ]

    private surfaceOptions: ISelectOption[] = [
        {
            text: "Hard",
            value: 'hard'
        },
        {
            text: "Clay",
            value: 'clay'
        }
    ]

    private statisticService = new StatisticService();

    private getSimulation() {
        const parsedOdds1 = parseNumber(this.state.odds1);
        const parsedOdds2 = parseNumber(this.state.odds2);
        this.props.getModelPrediction(this.props.p1Id, this.props.p2Id, this.state.selectedSurface, this.state.selectedTour, parsedOdds1, parsedOdds2);
    }

    private onChange(e: any) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    private correctPlayerInfo(): boolean {
        return this.props.player1 !== undefined && this.props.player2 !== undefined && this.props.p1Id === this.props.player1.playerId && this.props.p2Id === this.props.player2.playerId
    }
    private canFetchPrediction() {
        return tryParseNumber(this.state.odds1) && tryParseNumber(this.state.odds2);
    }

    private displayViewContent() {
        if (this.props.p1Error || this.props.p2Error) {
            return <TcErrorScreen tcError={this.props.p1Error || this.props.p2Error} />
        }
        else if (this.correctPlayerInfo()) {
            return (
                <React.Fragment>
                    <div className="modelContainer">
                        <div className="oddsContainer">
                            <span>{this.props.player1!.lastName}</span>
                            <TcInput tcPlaceholder={`Odds ${this.props.player1!.lastName}`} tcName="odds1" tcType="number" tcValue={this.state.odds1} tcOnChange={(e: any) => this.onChange(e)} />
                        </div>
                        <div className="oddsContainer">
                            <span>{this.props.player2!.lastName}</span>
                            <TcInput tcPlaceholder={`Odds ${this.props.player2!.lastName}`} tcName="odds2" tcType="number" tcValue={this.state.odds2} tcOnChange={(e: any) => this.onChange(e)} />
                        </div>
                    </div>
                    <div className="optionsContainer">
                        <TcSelect tcOptions={this.tourOptions} tcOnChange={(e: any) => this.onChange(e)} />
                        <TcSelect tcOptions={this.surfaceOptions} tcOnChange={(e: any) => this.onChange(e)} />
                    </div>
                    <div>
                        <TcButton tcDisabled={this.canFetchPrediction() === false} tcOnClick={(e: any) => this.getSimulation()}>Run model</TcButton>
                    </div>
                    {this.getPredictionContainer()}
                </React.Fragment>
            )
        }
        else {
            return <TcLoadingScreen />
        }
    }

    private getPredictionContainer() {
        if (this.props.modelError && !(this.props.model !== undefined && this.props.model.player1 === this.props.p1Id && this.props.model.player2 === this.props.p2Id)) {
            return (
                <div className="betContainer">
                    <p>{this.props.modelError}</p>
                </div>
            )
        } else if (this.props.model !== undefined && this.props.model.player1 === this.props.p1Id && this.props.model.player2 === this.props.p2Id) {
            let betText;
            if (this.props.model.reason) {
                betText = <p>{this.props.model.reason}</p>;
            } else if (this.props.model.unitSize === 0) {
                betText = <p>No bet recommended</p>;
            } else {
                betText = (
                    <React.Fragment>
                        <p className="betInfo">{this.props.player1!.lastName}: Odds {this.statisticService.roundNumber(this.props.model.simOdds1, 2)} | Edge {this.statisticService.roundNumber(this.props.model.edge1, 2)}</p>
                        <p className="betInfo">{this.props.player2!.lastName}: Odds {this.statisticService.roundNumber(this.props.model.simOdds2, 2)} | Edge {this.statisticService.roundNumber(this.props.model.edge2, 2)}</p>
                        <p className="recommendedBet">Recommended bet: {this.props.model.unitSize} units on {this.props.model.edge1 > this.props.model.edge2 ? this.props.player1!.lastName : this.props.player2!.lastName}</p>
                    </React.Fragment>
                )
            }
            return (
                <div className="betContainer">
                    {betText}
                </div>
            )
        }
    }

    render() {
        return (
            <section className="tcSection matchModel">
                <h2>Tc model</h2>
                {this.displayViewContent()}
            </section>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        player1: state.match.p1Results,
        player2: state.match.p2Results,

        p1Loading: state.match.p1Loading,
        p2Loading: state.match.p2Loading,

        p1Error: state.match.p1Error,
        p2Error: state.match.p2Error,

        model: state.match.model,
        modelLoading: state.match.modelLoading,
        modelError: state.match.modelError
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getModelPrediction: (p1Id: number, p2Id: number, surfaces: string, tours: string, odds1: number, odds2: number) => dispatch(getSimulation(p1Id, p2Id, surfaces, tours, odds1, odds2)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchModelComp);
