import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { GenderTypes, IPlayerInfo } from 'tennisconcrete-node';
import TcButton from '../../components/TcButton';
import TcErrorScreen from '../../components/TcErrorScreen';
import TcInput from '../../components/TcInput';
import TcLoadingScreen from '../../components/TcLoadingScreen';
import TcMain from '../../components/TcMain';
import TcMainHeader from '../../components/TcMainHeader';
import { getPlayersAct } from '../../redux/actions/playersActions';

import './PlayersView.scss';

interface IClassProps {
    location?: any;
    history?: any;

    players: IPlayerInfo[];
    playersError?: string;
    playersLoadings: boolean;
    getPlayersAct: (query: string) => any;
}

interface IClassState {
    inputSearch: string;
    firstTimeSearch: boolean;
    [input: string]: any;
}

class PlayersView extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
            inputSearch: "",
            firstTimeSearch: true
        }
    }

    private onInput(e: any) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    private onSearch(e: any) {
        e.preventDefault();
        this.props.getPlayersAct(this.state.inputSearch);
        if (this.state.firstTimeSearch) {
            this.setState({
                firstTimeSearch: false
            })
        }
    }

    private searchBttnDisabled() {
        return this.state.inputSearch.length < 3
    }

    private getGenderClass(gender: string) {
        switch (gender) {
            case "Female":
                return GenderTypes.Female;
            case "Male":
                return GenderTypes.Male
            default:
                return GenderTypes.Uknown;
        }
    }

    private displayResults() {
        if (this.props.playersLoadings) {
            return <TcLoadingScreen />
        }
        else if (this.props.playersError) {
            return <TcErrorScreen tcError={this.props.playersError} />
        }
        else if (this.state.firstTimeSearch) {
            return <TcErrorScreen tcError={`Type in your search!`} />
        }
        else if (this.props.players.length < 1) {
            return <TcErrorScreen tcError={`No results found for ${this.state.inputSearch}`} />
        }
        else {
            return (
                <div className="tableContainer">
                    {this.props.players.map((player: IPlayerInfo) => {
                        return (
                            <div className="result tableRow" key={`player_${player.playerId}`}>
                                <Link to={`/player/${player.playerId}`}>
                                    <div className="gender">
                                        <div className={`genderImg ${this.getGenderClass(player.gender)}`}></div>
                                    </div>
                                    <div className="name">
                                        <span>{player.fullName}</span>
                                    </div>
                                    <div className="country">
                                        <span>&nbsp;- {player.country.countryId}</span>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    render() {
        return (
            <TcMain tcClass="playersView">
                <TcMainHeader tcHeader="Search players" />
                <section className="tcSection search">
                    <h2>Search</h2>
                    <form onSubmit={(e: any) => this.onSearch(e)}>
                        <TcInput tcType="text" tcPlaceholder="Search players" tcName="inputSearch" tcValue={this.state.inputUsername} tcOnChange={(e: any) => this.onInput(e)} />
                        <TcButton tcDisabled={this.searchBttnDisabled()} tcOnClick={(e: any) => this.onSearch(e)}>Search players</TcButton>
                    </form>
                </section>
                <section className="tcSection results">
                    <h2>Results</h2>
                    {this.displayResults()}
                </section>
            </TcMain>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        players: state.players.players,
        playersError: state.players.playersError,
        playersLoading: state.players.playersLoading
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getPlayersAct: (query: string) => dispatch(getPlayersAct(query))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayersView);
