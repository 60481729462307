import { lsSetAuthtoken } from "../../scripts/LocalStorage";
import { AuthActionTypes } from "../actions/authActions";

const initialState = {
    authtoken: undefined,
    error: undefined,
    loading: undefined
};

export default function authReducer(state = initialState, action: any) {
    switch (action.type) {
        case AuthActionTypes.SET_AUTHTOKEN:
            lsSetAuthtoken(action.authtoken);
            return { ...state, authtoken: action.authtoken };
        case AuthActionTypes.ERROR_AUTHTOKEN:
            return { ...state, error: action.error };
        case AuthActionTypes.LOADING_AUTHTOKEN:
            return { ...state, loading: action.loading };
        default:
            return state;
    }
}
