import React from 'react';
import { connect } from 'react-redux';
import { ITournamentInfo, IQueueItem, ETours, EStatusTypes, ITournament, IMatch, isRetirement } from 'tennisconcrete-node';
import TcButton from '../../components/TcButton';
import { Link } from 'react-router-dom';

import TcLoading from '../../components/TcLoading';
import { getTournamentsAdminAct, postFetchTournAct } from '../../redux/actions/adminActions';

interface IAdminTournamentsCompProps {
    log: IQueueItem[];
    tournaments: ITournament[];
    tournamentsLoading: boolean;
    tournamentsError?: string;
    inputTour: string;
    inputYear: number;
    disabledInterval: number;
    getTournamentsAct: (year: number) => any;
    postFetchTournAct: (tournId: number) => any;
}

interface IAdminTournamentsCompState {
}

class AdminTournamentsComp extends React.Component<IAdminTournamentsCompProps, IAdminTournamentsCompState> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.props.getTournamentsAct(this.props.inputYear);
    }

    getTournaments() {
        switch (this.props.inputTour) {
            case "WTA":
                return this.props.tournaments.filter(it => it.tour === 'WTA');
            case "ITF":
                return this.props.tournaments.filter(it => it.tour === 'ITF Female');
            case "ATP":
                return this.props.tournaments.filter(it => it.tour === 'ATP');
            case "ATP Challenger":
                return this.props.tournaments.filter(it => it.tour === 'ATP Challenger');
            default:
                return this.props.tournaments.filter(it => !['WTA', 'ITF Female', 'ATP', 'ATP Challenger'].includes(it.tour))
        }
    }

    getFetchButton(tournament: ITournament) {
        const activeQueueItem = this.props.log.reverse().find((item: IQueueItem) => item.info === tournament.tournamentId.toString() && item.statusData);
        const buttonDisabled = !!activeQueueItem && activeQueueItem.status !== EStatusTypes.STATUS_ERROR && activeQueueItem.status !== EStatusTypes.STATUS_DONE ? true : false;
        const fetchedResults = !!activeQueueItem && activeQueueItem.statusData !== null && (activeQueueItem.statusData!.fetchedResults! > tournament.fetchedResults || activeQueueItem.status === EStatusTypes.STATUS_WORKING) ? activeQueueItem.statusData!.fetchedResults : tournament.fetchedResults;
        const totalResults = !!activeQueueItem && activeQueueItem.statusData !== null && (activeQueueItem.statusData!.totalResults! > tournament.totalResults || activeQueueItem.status === EStatusTypes.STATUS_WORKING) ? activeQueueItem?.statusData?.totalResults : tournament.totalResults;
        const buttonContent = !totalResults ? 'N.Y.F.' : `${fetchedResults}/${totalResults}`;
        return <TcButton tcDisabled={buttonDisabled} tcOnClick={(e: any) => this.handleFetchTournClick(e, tournament.tournamentId)}>{buttonContent}</TcButton>
    };

    handleFetchTournClick(e: any, tournId: number) {
        e.target.setAttribute('disabled', 'disabled');
        // TODO: remove
        //setTimeout(() => {
        //    e.target.removeAttribute('disabled');
        //}, this.props.disabledInterval);
        this.props.postFetchTournAct(tournId);
    }

    displayTournaments() {
        if (this.props.tournamentsError !== undefined) {
            return (
                <div className="tournsListItem">
                    <span className="error">{this.props.tournamentsError}</span>
                </div>
            )
        }
        else if (this.getTournaments().length > 0 && !this.props.tournamentsLoading) {
            return this.getTournaments().map((tournament: ITournament) => {
                return (
                    <div className="tournsListItem" key={`tourn_${tournament.tournamentId}`}>
                        <Link to={`/tournament/${tournament.tournamentId}`} className="tournName">{[ETours.ATP_GRANDSLAM.toString(), ETours.WTA_GRANDSLAM.toString()].includes(tournament.tour) ? `${tournament.name} (${tournament.tour.split(" ")[0]})` : `${tournament.name}`}</Link>
                        <span className="statsCounter">{tournament.results.reduce((counter: number, match: IMatch) => {
                            if (match.acesA !== null && match.duration !== null && match.duration > 0 || isRetirement(match)) counter++;
                            return counter;
                        }, 0)} stats</span>
                        <span className="tournButton">{this.getFetchButton(tournament)}</span>
                    </div>
                )
            })
        }
        else if (this.getTournaments().length <= 0 && !this.props.tournamentsLoading) {
            return (
                <div className="tournsListItem">
                    <span className="noResults">No tournaments found!</span>
                </div>
            )
        }
        else {
            return <TcLoading />
        }
    }

    render() {
        return (
            <section className="actionTourns tcSection">
                <h2>Tournaments</h2>
                <div className="actionTournsList">
                    {this.displayTournaments()}
                </div>
            </section>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        log: state.admin.log,
        tournaments: state.admin.tournaments,
        tournamentsLoading: state.admin.tournamentsLoading,
        tournamentsError: state.admin.tournamentsError,
        inputYear: state.admin.inputYear,
        inputTour: state.admin.inputTour
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getTournamentsAct: (year: number) => dispatch(getTournamentsAdminAct(year)),
        postFetchTournAct: (tournId: number) => dispatch(postFetchTournAct(tournId))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTournamentsComp);
