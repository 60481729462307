import React from 'react';
import { connect } from 'react-redux';
import { EMessageTypes, IQueueItem } from 'tennisconcrete-node';
import { getTimeFromISO, jsonDateParser } from '../../scripts/mapFunctions';

import TcLoading from '../../components/TcLoading';
import { fetchLogAct } from '../../redux/actions/adminActions';

interface IAdminLogCompProps {
    log: IQueueItem[];
    logLoading: boolean;
    logError?: string;
    getLogAct: () => any;
}

interface IAdminLogCompState {
    disabledInterval: number;
    refreshLogInterval: any;
}

class AdminLogComp extends React.Component<IAdminLogCompProps, IAdminLogCompState> {

    constructor(props: any) {
        super(props);
        this.state = {
            disabledInterval: 3000,
            refreshLogInterval: undefined
        }
    }

    public componentDidMount() {
        this.props.getLogAct();
        this.setState({
            refreshLogInterval: setInterval(() => {
                this.props.getLogAct();
            }, 2000)
        });
    }

    public componentWillUnmount() {
        clearInterval(this.state.refreshLogInterval);
        this.setState({
            refreshLogInterval: undefined
        });
    }

    private getMessageTypeString(type: EMessageTypes) {
        switch (type) {
            case EMessageTypes.FETCH_TOURNAMENT:
                return "Tourn.";
            case EMessageTypes.FETCH_TOURNAMENTS:
                return "Tourns.";
            case EMessageTypes.CALC_YEARLYAVG:
                return "Avg.";
            case EMessageTypes.FETCH_SCHEDULE:
                return "Schedule";
            default:
                return "Unkown"
        }
    }

    private displayLogItems() {
        if (this.props.logError !== undefined) {
            return (
                <div className="eventLogItem">
                    <span className="error">{this.props.logError}</span>
                </div>
            )
        } else if (this.props.log.length > 0) {
            const items = JSON.parse(JSON.stringify(this.props.log), jsonDateParser);
            items.reverse();
            return items.map((logItem: IQueueItem) => {
                return (
                    <div className="eventLogItem" key={`LOGITEM_${logItem.timestamp}_${logItem.type}_${logItem.info}`}>
                        <span className="datetime">{getTimeFromISO(logItem.timestamp)}</span>
                        <span className="type">{this.getMessageTypeString(logItem.type)}</span>
                        <span className={`status ${logItem.status}`}>{logItem.status}</span>
                        <span className="statusInfo">{logItem.statusInfo}</span>
                    </div>
                )
            });
        }
        else if (this.props.log.length <= 0 && !this.props.logLoading) {
            return (
                <div className="eventLogItem">
                    <span className="noResults">No queue items found!</span>
                </div>
            );
        }
        else {
            return <TcLoading />
        }
    }

    render() {
        return (
            <section className="eventsLog tcSection">
                <h2>Log</h2>
                <div className="eventLog">
                    {this.displayLogItems()}
                </div>
            </section>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        log: state.admin.log,
        logLoading: state.admin.loading,
        logError: state.admin.error,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getLogAct: () => dispatch(fetchLogAct()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogComp);
