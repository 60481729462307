import { IAuthtoken } from "tennisconcrete-node";

export function lsGetAuthtoken(): IAuthtoken | undefined {
    const authtoken = localStorage.getItem('authtoken');
    if (authtoken !== null) return JSON.parse(authtoken);
    else return undefined;
}

export function lsSetAuthtoken(authtoken: IAuthtoken) {
    localStorage.setItem('authtoken', JSON.stringify(authtoken));
}