import { ScheduleActionTypes } from '../actions/scheduleActions';

const initialState = {
    schedule: [],
    loading: false,
    error: undefined,
};

export default function scheduleReducer(state = initialState, action: any) {
    switch (action.type) {
        case ScheduleActionTypes.SET_SCHEDULE:
            return { ...state, schedule: action.schedule };
        case ScheduleActionTypes.LOADING_SCHEDULE:
            return { ...state, loading: action.loading };
        case ScheduleActionTypes.ERROR_SCHEDULE:
            return { ...state, error: action.error };
        default:
            return state;
    }
}
