import { FilterTypes } from "tennisconcrete-node";
import { ISelectOption } from "../../entities/ISelectOption";
import { MatchActionTypes } from "../actions/matchActions";

const filterTypeOptions: ISelectOption[] = [
    {
        value: JSON.stringify({ filterType: FilterTypes.WEEKS, filterValue: '52' }),
        text: "Last year"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.WEEKS, filterValue: '104' }),
        text: "Last 2 years"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.WEEKS, filterValue: '26' }),
        text: "Last 6 months"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.TOURNAMENTS, filterValue: '1' }),
        text: "Last tournament"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.TOURNAMENTS, filterValue: '5' }),
        text: "Last 5 tournaments"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.TOURNAMENTS, filterValue: '10' }),
        text: "Last 10 tournaments"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.TOURNAMENTS, filterValue: '20' }),
        text: "Last 20 tournaments"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.YEAR, filterValue: new Date().getUTCFullYear() }),
        text: `${new Date().getUTCFullYear()}`
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.YEAR, filterValue: new Date().getUTCFullYear() - 1 }),
        text: `${new Date().getUTCFullYear() - 1}`
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.YEAR, filterValue: new Date().getUTCFullYear() - 2 }),
        text: `${new Date().getUTCFullYear() - 2}`
    },
];

const initialState = {
    filterTypeOptions: filterTypeOptions,

    avgResult: undefined,
    avgError: undefined,
    avgLoading: false,

    p1Results: undefined,
    p2Results: undefined,

    p1NotesError: undefined,
    p2NotesError: undefined,

    p1Notes: undefined,
    p2Notes: undefined,

    p1Error: undefined,
    p2Error: undefined,

    p1Loading: false,
    p2Loading: false,

    model: undefined,
    modelLoading: false,
    modelError: undefined
};

export default function matchReducer(state = initialState, action: any) {
    switch (action.type) {
        case MatchActionTypes.SET_P1_RESULTS:
            return { ...state, p1Results: action.results }
        case MatchActionTypes.SET_P2_RESULTS:
            return { ...state, p2Results: action.results }
        case MatchActionTypes.SET_P1_NOTES:
            return { ...state, p1Notes: action.notes }
        case MatchActionTypes.SET_P2_NOTES:
            return { ...state, p2Notes: action.notes }
        case MatchActionTypes.SET_P1_NOTES_ERROR:
            return { ...state, p1NotesError: action.error }
        case MatchActionTypes.SET_P2_NOTES_ERROR:
            return { ...state, p2NotesError: action.error }
        case MatchActionTypes.SET_P1_ERROR:
            return { ...state, p1Error: action.error }
        case MatchActionTypes.SET_P2_ERROR:
            return { ...state, p2Error: action.error }
        case MatchActionTypes.SET_P1_LOADING:
            return { ...state, p1Loading: action.loading }
        case MatchActionTypes.SET_P2_LOADING:
            return { ...state, p2Loading: action.loading }
        case MatchActionTypes.SET_AVG:
            return { ...state, avgResult: action.average }
        case MatchActionTypes.SET_AVG_LOADING:
            return { ...state, avgLoading: action.loading }
        case MatchActionTypes.SET_AVG_ERROR:
            return { ...state, avgError: action.error }
        case MatchActionTypes.SET_MODEL:
            return { ...state, model: action.model }
        case MatchActionTypes.SET_MODEL_LOADING:
            return { ...state, modelLoading: action.loading }
        case MatchActionTypes.SET_MODEL_ERROR:
            return { ...state, modelError: action.error }
        default:
            return state;
    }
}
