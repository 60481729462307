import { AdminActionTypes } from '../actions/adminActions';

const tourSelectValues = [
    { value: "WTA", text: "WTA" },
    { value: "ITF", text: "ITF" },
    { value: "ATP", text: "ATP" },
    { value: "ATP Challenger", text: "ATP Challenger" },
    { value: "Misc", text: "Misc" }
]

function yearSelectValues() {
    let years = [];
    let currentYear = new Date().getUTCFullYear();
    for (let year = 2015; year <= currentYear; year++) {
        years.push({ value: year, text: year });
    }
    return years.reverse();
};

const initialState = {
    log: [],
    logLoading: false,
    logError: undefined,

    tournaments: [],
    tournamentsLoading: false,
    tournamentsError: undefined,
    errorPost: undefined,

    inputYear: yearSelectValues()[0].value,
    inputTour: tourSelectValues[0].value,
    inputYearValues: yearSelectValues(),
    inputTourValues: tourSelectValues
};

export default function adminReducer(state = initialState, action: any) {
    switch (action.type) {
        case AdminActionTypes.SET_LOG:
            return { ...state, log: action.log };
        case AdminActionTypes.LOADING_LOG:
            return { ...state, logLoading: action.loading };
        case AdminActionTypes.ERROR_LOG:
            return { ...state, logError: action.error };
        case AdminActionTypes.SET_TOURNEYS:
            return { ...state, tournaments: action.tournaments };
        case AdminActionTypes.LOADING_TOURNEYS:
            return { ...state, tournamentsLoading: action.loading };
        case AdminActionTypes.ERROR_TOURNEYS:
            return { ...state, tournamentsError: action.error };

        case AdminActionTypes.ERROR_POST:
            return { ...state, error: action.error };

        case AdminActionTypes.SET_USERINPUT_YEAR:
            return { ...state, inputYear: action.year };
        case AdminActionTypes.SET_USERINPUT_TOUR:
            return { ...state, inputTour: action.tour };
        default:
            return state;
    }
}
