import React from 'react';
import { connect } from 'react-redux';
import TcButton from '../../components/TcButton';

import './AdminView.scss';
import { postCalcAverageAct, postFetchScheduleAct, postFetchTournsAct } from '../../redux/actions/adminActions';

interface IAdminActionsCompProps {
    inputYear: number;
    errorPost?: string;
    postFetchScheduleAct: () => any;
    postCalcAverageAct: () => any;
    postFetchTournsAct: (year: number) => any;
}

interface IAdminActionsCompState {
    scheduleDisabled: boolean;
    calcAvgDisabled: boolean;
    fetchTournsDisabled: boolean;
    disabledInterval: number;
}

class AdminActionsComp extends React.Component<IAdminActionsCompProps, IAdminActionsCompState> {

    constructor(props: any) {
        super(props);
        this.state = {
            scheduleDisabled: false,
            calcAvgDisabled: false,
            fetchTournsDisabled: false,
            disabledInterval: 3000
        }
    }

    scheduleClick() {
        this.setState({
            scheduleDisabled: true
        });
        setTimeout(() => {
            this.setState({
                scheduleDisabled: false
            })
        }, this.state.disabledInterval);
        this.props.postFetchScheduleAct();
    }

    calcAverageClick() {
        this.setState({
            calcAvgDisabled: true
        });
        setTimeout(() => {
            this.setState({
                calcAvgDisabled: false
            })
        }, this.state.disabledInterval);
        this.props.postCalcAverageAct();
    }

    fetchTournsClick() {
        this.setState({
            fetchTournsDisabled: true
        });
        setTimeout(() => {
            this.setState({
                fetchTournsDisabled: false
            })
        }, this.state.disabledInterval);
        this.props.postFetchTournsAct(this.props.inputYear);
    }

    render() {
        return (
            <section className="actionsMisc tcSection">
                <h2>Actions</h2>
                {this.props.errorPost ?? (
                    <span className="error">{this.props.errorPost}</span>
                )}
                <div className="actionsButtons">
                    <TcButton tcDisabled={this.state.scheduleDisabled} tcOnClick={() => this.scheduleClick()}>Schedule</TcButton>
                    <TcButton tcDisabled={this.state.calcAvgDisabled} tcOnClick={() => this.calcAverageClick()}>Average</TcButton>
                    <TcButton tcDisabled={this.state.fetchTournsDisabled} tcOnClick={() => this.fetchTournsClick()}>Tourns.</TcButton>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        errorPost: state.admin.errorPost,
        inputYear: state.admin.inputYear
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        postFetchScheduleAct: () => dispatch(postFetchScheduleAct()),
        postCalcAverageAct: () => dispatch(postCalcAverageAct()),
        postFetchTournsAct: (year: number) => dispatch(postFetchTournsAct(year)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminActionsComp);
