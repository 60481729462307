import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import Store from './scripts/store';
import ScheduleView from './views/ScheduleView/ScheduleView';
import LoginView from './views/LoginView/LoginView';
import AdminView from './views/AdminView/AdminView';

import './styles/style.scss';
import ScheduleTournamentView from './views/ScheduleTournamentView/ScheduleTournamentView';
import MatchView from './views/MatchView/MatchView';
import TcNav from './components/TcNav';
import TcPrivateRoute from './components/TcPrivateRoute';
import { IAuthtoken } from 'tennisconcrete-node';
import { lsGetAuthtoken } from './scripts/LocalStorage';
import { setAuthtokenAct } from './redux/actions/authActions';
import PlayersView from './views/PlayersView/PlayersView';
import PlayerView from './views/PlayerView/PlayerView';

const authtoken: IAuthtoken | undefined = lsGetAuthtoken();
if (authtoken !== undefined) Store.dispatch(setAuthtokenAct(authtoken));

const indexComponent = (
    <Provider store={Store}>
        <BrowserRouter>
            <TcNav />
            <Switch>
                <Route exact path='/login' component={LoginView} />

                <TcPrivateRoute exact path="/">
                    <Redirect to="/schedule" />
                </TcPrivateRoute>

                <TcPrivateRoute exact path='/schedule' component={ScheduleView} />
                <TcPrivateRoute exact path='/schedule/:id' component={ScheduleTournamentView} />
                <TcPrivateRoute exact path='/admin' component={AdminView} />
                <TcPrivateRoute exact path='/match' component={MatchView} />
                <TcPrivateRoute exact path='/players' component={PlayersView} />
                <TcPrivateRoute exact path='/player/:id' component={PlayerView} />
            </Switch>
            {/* Footer */}
        </BrowserRouter>
    </Provider>
);


ReactDOM.render(indexComponent, document.getElementById('root'));
