import React from 'react';
import { connect } from 'react-redux';
import { FilterTypes, IPlayer } from 'tennisconcrete-node';

import { ISelectOption } from '../../entities/ISelectOption';
import TcSelect from '../../components/TcSelect';
import TcCheckbox from '../../components/TcCheckbox';
import TcButton from '../../components/TcButton';
import { getPlayerAct } from '../../redux/actions/playerActions';

interface IClassProps {
    // Input
    playerId: number;

    // Redux
    player?: IPlayer;
    playerLoading: boolean;
    playerError?: string;

    filterTypeOptions: ISelectOption[];
    getPlayerAct: (playerId: number, filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) => any;
}

// Tbh this can be moved into a seperate picker component...
interface IClassState {
    // Surface filters
    surfaceHard: boolean,
    surfaceIndoor: boolean,
    surfaceClay: boolean,
    surfaceGrass: boolean

    // Tour filters
    tourWTA: boolean,
    tourITF: boolean,
    tourATP: boolean,
    tourChallenger: boolean

    filterType: FilterTypes;
    filterValue: string;

    filterDisabled: boolean;

    // For handling any state change
    [input: number]: any;
}


class MatchFiltersComp extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        const selectedValue = JSON.parse(props.filterTypeOptions[0].value);
        this.state = {
            surfaceHard: true,
            surfaceIndoor: false,
            surfaceClay: false,
            surfaceGrass: false,

            tourWTA: true,
            tourITF: false,
            tourATP: false,
            tourChallenger: false,

            filterType: selectedValue.filterType,
            filterValue: selectedValue.filterValue,

            filterDisabled: false,
        }
    }

    public componentDidMount() {
        this.getPlayer();
    }

    private handleFilterChage(e: any) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const filterType = JSON.parse(value).filterType;
        const filterValue = JSON.parse(value).filterValue;
        this.setState({
            filterType: filterType,
            filterValue: filterValue
        });
    }

    private onFilterClick() {
        this.setState({
            filterDisabled: true
        }, () => this.getPlayer());
        setTimeout(() => {
            this.setState({
                filterDisabled: false
            })
        }, 2000);
    }

    private getPlayer() {
        const surfaces = [];
        if (this.state.surfaceHard) surfaces.push('hard');
        if (this.state.surfaceIndoor) surfaces.push('indoor');
        if (this.state.surfaceClay) surfaces.push('clay');
        if (this.state.surfaceGrass) surfaces.push('grass');

        const tours = [];
        if (this.state.tourWTA) tours.push('wta');
        if (this.state.tourITF) tours.push('itf');
        if (this.state.tourATP) tours.push('atp');
        if (this.state.tourChallenger) tours.push('challenger');

        this.props.getPlayerAct(this.props.playerId, this.state.filterType, this.state.filterValue, surfaces, tours);
    }

    private onChangeCheck(e: any) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    private filterButtonDisabled() {
        const surfaceDisabled = !this.state.surfaceHard && !this.state.surfaceIndoor && !this.state.surfaceClay && !this.state.surfaceGrass;
        const tourDisabled = !this.state.tourWTA && !this.state.tourITF && !this.state.tourATP && !this.state.tourChallenger;
        const loadingDisabled = this.props.playerLoading || this.state.filterDisabled;
        return surfaceDisabled || tourDisabled || loadingDisabled;
    }

    render() {
        return (
            <section className="matchFilter tcSection">
                <h2>Filter options</h2>
                <div className="filterOptions">
                    <div className="filterGroups">
                        <div className="filterGroup">
                            <TcCheckbox tcId="tourWTA" tcName="tourWTA" tcValue={this.state.tourWTA} tcLabel="WTA" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="tourITF" tcName="tourITF" tcValue={this.state.tourITF} tcLabel="ITF" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="tourATP" tcName="tourATP" tcValue={this.state.tourATP} tcLabel="ATP" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="tourChallenger" tcName="tourChallenger" tcValue={this.state.tourChallenger} tcLabel="Challenger" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                        </div>
                        <div className="filterGroup">
                            <TcCheckbox tcId="surfaceHard" tcName="surfaceHard" tcValue={this.state.surfaceHard} tcLabel="Hard Court" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="surfaceIndoor" tcName="surfaceIndoor" tcValue={this.state.surfaceIndoor} tcLabel="Indoors" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="surfaceClay" tcName="surfaceClay" tcValue={this.state.surfaceClay} tcLabel="Clay Court" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                            <TcCheckbox tcId="surfaceGrass" tcName="surfaceGrass" tcValue={this.state.surfaceGrass} tcLabel="Grass Court" tcOnChange={(e: any) => this.onChangeCheck(e)} />
                        </div>
                    </div>
                    <div className="filterGroups">
                        <TcSelect tcId="inputTour" tcName="inputTour" tcOnChange={(e: any) => this.handleFilterChage(e)} tcOptions={this.props.filterTypeOptions} />
                        <TcButton tcDisabled={this.filterButtonDisabled()} tcOnClick={() => this.onFilterClick()}>Filter</TcButton>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        filterTypeOptions: state.match.filterTypeOptions,

        player: state.player.player,
        playerLoading: state.player.playerLoading,
        playerError: state.player.playerError,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getPlayerAct: (playerId: number, filterType: FilterTypes, filterValue: string, surfaces: string[], tours: string[]) => dispatch(getPlayerAct(playerId, filterType, filterValue, surfaces, tours)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchFiltersComp);
