import React from 'react';

import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { IAuthtoken } from "tennisconcrete-node";
import { getClassNames } from "../scripts/mapFunctions";
import { SvgIconNavAccount } from "../svg/SvgIconNavAccount";
import { SvgIconNavAdmin } from "../svg/SvgIconNavAdmin";
import { SvgIconNavPlayers } from "../svg/SvgIconNavPlayers";
import { SvgIconNavSchedule } from "../svg/SvgIconNavSchedule";
import { SvgIconNavTournaments } from "../svg/SvgIconNavTournaments";

interface IClassProps {
    tcClass?: string;
    authtoken?: IAuthtoken;
}

interface IClassState {
}

class TcNav extends React.Component<IClassProps, IClassState> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    render() {
        if (this.props.authtoken !== undefined && this.props.authtoken.activated === true) {
            return (
                <nav className={`tcNav${getClassNames(this.props.tcClass)}`}>
                    <div className="navItem">
                        <NavLink to="/schedule" activeClassName="selected">
                            <SvgIconNavSchedule />
                        </NavLink>
                    </div>
                    <div className="navItem">
                        <NavLink to="/players" activeClassName="selected">
                            <SvgIconNavPlayers />
                        </NavLink>
                    </div>
                    <div className="navItem">
                        <NavLink to="/tournaments" activeClassName="selected">
                            <SvgIconNavTournaments />
                        </NavLink>
                    </div>
                    <div className="navItem" id="settingsNav">
                        <NavLink to="/admin" activeClassName="selected">
                            <SvgIconNavAdmin />
                        </NavLink>
                    </div> { /* TOODO: Remove button when logged in not as Admin */}
                    <div className="navItem">
                        <NavLink to="/account" activeClassName="selected">
                            <SvgIconNavAccount />
                        </NavLink>
                    </div>
                </nav>
            )
        }
        else return <React.Fragment />;
    }
}

function mapStateToProps(state: any) {
    return {
        authtoken: state.auth.authtoken,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TcNav);
