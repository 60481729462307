import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import mainReducer from '../redux/reducers/mainReducer';

const logger = (store: any) => (next: any) => (action: any) => {
	//console.log('ACTION:', action.type, action);
	let result = next(action);
	//console.log('STATE AFTER ACTION:', action.type, store.getState());
	return result;
};

export default createStore(mainReducer, applyMiddleware(logger, thunk));
