import { FilterTypes } from "tennisconcrete-node";
import { ISelectOption } from "../../entities/ISelectOption";
import { PlayerActionTypes } from "../actions/playerActions";

const filterTypeOptions: ISelectOption[] = [
    {
        value: JSON.stringify({ filterType: FilterTypes.WEEKS, filterValue: '52' }),
        text: "Last year"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.WEEKS, filterValue: '104' }),
        text: "Last 2 years"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.WEEKS, filterValue: '26' }),
        text: "Last 6 months"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.TOURNAMENTS, filterValue: '1' }),
        text: "Last tournament"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.TOURNAMENTS, filterValue: '5' }),
        text: "Last 5 tournaments"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.TOURNAMENTS, filterValue: '10' }),
        text: "Last 10 tournaments"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.TOURNAMENTS, filterValue: '20' }),
        text: "Last 20 tournaments"
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.YEAR, filterValue: new Date().getUTCFullYear() }),
        text: `${new Date().getUTCFullYear()}`
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.YEAR, filterValue: new Date().getUTCFullYear() - 1 }),
        text: `${new Date().getUTCFullYear() - 1}`
    },
    {
        value: JSON.stringify({ filterType: FilterTypes.YEAR, filterValue: new Date().getUTCFullYear() - 2 }),
        text: `${new Date().getUTCFullYear() - 2}`
    },
];

const initialState = {
    filterTypeOptions: filterTypeOptions,

    player: undefined,
    playerError: undefined,
    playerLoading: false,

    notes: undefined,
    notesError: undefined,
    notesLoading: false,
};

export default function playerReducer(state = initialState, action: any) {
    switch (action.type) {
        case PlayerActionTypes.SET_PLAYER_RESULTS:
            return { ...state, player: action.player }
        case PlayerActionTypes.SET_PLAYER_ERROR:
            return { ...state, playerError: action.error }
        case PlayerActionTypes.SET_PLAYER_LOADING:
            return { ...state, playerLoading: action.loading }
        case PlayerActionTypes.SET_PLAYER_NOTES:
            return { ...state, notes: action.notes }
        case PlayerActionTypes.SET_PLAYER_NOTES_ERROR:
            return { ...state, notesError: action.error }
        case PlayerActionTypes.SET_PLAYER_NOTES_LOADING:
            return { ...state, notesLoading: action.loading }
        default:
            return state;
    }
}
