import { IScheduledTournament } from "tennisconcrete-node";
import { Link } from 'react-router-dom';

export default function ScheduleList(props: any) {
    const getTournaments = (tournType: string, schedule: IScheduledTournament[]): IScheduledTournament[] => {
        return schedule.filter((tournament) => tournament.tournamentName.includes(tournType));
    };

    const getUnfilteredTournaments = (tournType: string, schedule: IScheduledTournament[]): IScheduledTournament[] => {
        const splitTournamentTypes: string[] = tournType.split(", ");
        return schedule.filter((tournament) => !splitTournamentTypes.some((tournamentType) => tournament.tournamentName.includes(tournamentType)));
    };

    const getTournamentList = (tournType: string, schedule: IScheduledTournament[], filterFunc: { (tournType: string, schedule: IScheduledTournament[]): IScheduledTournament[] }) => {
        const tournaments: IScheduledTournament[] = filterFunc(tournType, schedule);
        if (tournaments.length < 1) {
            return (
                <li className='noResults'>
                    <span>No tournaments</span>
                </li>
            );
        } else {
            return tournaments.map((tournament) => {
                return (
                    <li key={tournament.tournamentName}>
                        <Link to={`/schedule/${tournament.tournamentId}`}>{tournament.tournamentName}</Link>
                    </li>
                );
            });
        }
    };

    return (
        <section className="scheduleList tcSection">
            <h2>WTA:</h2>
            <ul>{getTournamentList('WTA', props.schedule, getTournaments)}</ul>
            <h2>ITF:</h2>
            <ul>{getTournamentList('ITF', props.schedule, getTournaments)}</ul>
            <h2>ATP:</h2>
            <ul>{getTournamentList('ATP', props.schedule, getTournaments)}</ul>
            <h2>Misc:</h2>
            <ul>{getTournamentList('ITF, WTA, ATP', props.schedule, getUnfilteredTournaments)}</ul>
        </section>
    )
}