import React from 'react';
import { connect } from 'react-redux';

import './AdminView.scss';
import { getTournamentsAdminAct, setUserInputYearAct, setUserInputTourAct } from '../../redux/actions/adminActions';
import TcSelect from '../../components/TcSelect';

interface IAdminFiltersCompwProps {
    inputYearValues: any[];
    inputTourValues: any[];
    getTournamentsAct: (year: number) => any;
    setUserInputYearAct: (year: number) => any;
    setUserInputTourAct: (tour: string) => any;
}

interface IAdminFiltersCompState {
    inputYearDisabled: boolean;
    disabledInterval: number;
    inputYear: number;
    inputTour: string;

    // For handling any state change
    [input: number]: any;
}

class AdminFiltersComp extends React.Component<IAdminFiltersCompwProps, IAdminFiltersCompState> {

    constructor(props: any) {
        super(props);
        this.state = {
            inputYearDisabled: false,
            disabledInterval: 3000,

            inputYear: props.inputYearValues[0].value,
            inputTour: props.inputTourValues[0].value
        }
    }

    handleTourChange(e: any) {
        this.props.setUserInputTourAct(e.target.value);
        this.handleSelectChange(e);
    }

    handleYearChange(e: any) {
        this.setState({
            inputYearDisabled: true
        });
        setTimeout(() => {
            this.setState({
                inputYearDisabled: false
            })
        }, this.state.disabledInterval);
        this.props.setUserInputYearAct(e.target.value);
        this.props.getTournamentsAct(e.target.value);
        this.handleSelectChange(e);
    }

    handleSelectChange(e: any) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <section className="actionsFilter tcSection">
                <h2>Filter options</h2>
                <div className="selectInputs">
                    <TcSelect tcId="inputTour" tcName="inputTour" tcOnChange={(e: any) => this.handleTourChange(e)} tcOptions={this.props.inputTourValues} />
                    <TcSelect tcDisabled={this.state.inputYearDisabled} tcId="inputYear" tcName="inputYear" tcOnChange={(e: any) => this.handleYearChange(e)} tcOptions={this.props.inputYearValues} />
                </div>
            </section>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        inputYearValues: state.admin.inputYearValues,
        inputTourValues: state.admin.inputTourValues
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getTournamentsAct: (year: number) => dispatch(getTournamentsAdminAct(year)),
        setUserInputYearAct: (year: number) => dispatch(setUserInputYearAct(year)),
        setUserInputTourAct: (tour: string) => dispatch(setUserInputTourAct(tour))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminFiltersComp);
